export interface ValidationError {
  param: string;
  value: any;
  msg: string;
}

export interface ApiError {
  message: string;
  error?: ValidationError[];
}

export const parseApiError = (e: unknown): ApiError => {
  if (typeof e === 'string') {
    return { message: e };
  }
  
  const error = e as any;
  if (error.response?.data) {
    return error.response.data;
  }
  
  return { message: String(e) };
};
