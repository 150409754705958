import React, { useCallback, useEffect, useState } from "react";
import { HumanizedDate } from "@/components/elements";
import { JsonDisplay } from "@/components/elements/JsonDisplay";
import { Table } from "@/components/datadisplay/Table";
import { t, Trans } from "@lingui/macro";
import { DLQJob } from "@/types/dlqJob.dto";

import { useMockingbirdSabreQueueService } from "../../../services/useMockingbirdSabreQueueService";

interface TableItem {
  id: number;
  [key: string]: React.ReactNode;
}

interface QueueJob extends TableItem {
  id: number;
  jobId: string;
  data: React.ReactNode;
  failedReason: string | null;
  timestamp: React.ReactNode;
  [key: string]: React.ReactNode;
}

const transformQueueData = (data: DLQJob[]): QueueJob[] => {
  return (
    data?.map((job, index) => ({
      id: index,
      jobId: job.id,
      data: <JsonDisplay data={JSON.stringify(job.data)} />,
      failedReason: job.failedReason,
      timestamp: <HumanizedDate date={new Date(job.timestamp).toISOString()} />,
    })) || []
  );
};

export const QueueAdminPage: React.FC = () => {
  const { getDLQJobs, requeueJob, deleteJob } =
    useMockingbirdSabreQueueService();
  const [jobs, setJobs] = useState<DLQJob[]>([]);
  const columns = [
    { key: "jobId", title: "ID", sortable: false },
    { key: "data", title: "Data", sortable: false },
    { key: "failedReason", title: "Failed Reason", sortable: false },
    { key: "timestamp", title: "Timestamp", sortable: false },
  ];

  const fetchJobs = useCallback(async () => {
    const fetchedJobs = await getDLQJobs();
    setJobs(fetchedJobs);
  }, [getDLQJobs]);

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleRequeue = async (item: TableItem) => {
    const job = item as QueueJob;
    const result = await requeueJob(job.jobId);
    if (result) {
      // Refresh the jobs list after successful requeue
      fetchJobs();
    }
  };

  const handleDelete = async (id: number) => {
    const job = jobs[id];
    if (job) {
      const result = await deleteJob(job.id);
      if (result) {
        // Refresh the jobs list after successful delete
        fetchJobs();
      }
    }
  };

  const displayData = transformQueueData(jobs);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            <Trans>Manage Dead Letter Queue</Trans>
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            <Trans>Interface for managing Sabre payloads that failed to process. Requeue or delete failed jobs.</Trans>
          </p>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <Table
                data={displayData}
                columns={columns}
                onDelete={handleDelete}
                extraAction={{ label: t`Requeue`, onAction: handleRequeue }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueueAdminPage;
