import {
  ReservationDetailDTO,
  UpdateReservationDTO,
} from "@/types/reservation.dto";
import { StackedInputGroup } from "@/components/forms/Input";
import { t } from "@lingui/macro";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "@/components/elements/Button";

type PreferenceProps = {
  reservation: ReservationDetailDTO;
};

export function Preference({ reservation }: PreferenceProps) {
  const methods = useForm<UpdateReservationDTO>();

  const onSubmit = async (data: UpdateReservationDTO) => {
    console.log("Submitted", data);
  };

  return (
    <div className="rounded-lg bg-white border-gray-100 border mx-8">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="space-y-6 p-4"
        >
          {/* Form Fields */}
          <div className="grid grid-cols-2 gap-4">
            <StackedInputGroup
              label={t`Comment`}
              name="comment"
              type="text"
              options={{disabled: true}}
            />
            <StackedInputGroup
              label={t`Bed Configuration`}
              name="bedConfiguration"
              type="text"
              options={{disabled: true}}
            />
            <StackedInputGroup
              label={t`Smoking`}
              name="smoking"
              type="text"
              options={{disabled: true}}
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <Button type="primary">{t`Update Reservation`}</Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
