import {
  ArrowDownTrayIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { Trans } from "@lingui/macro";
import { useFormContext } from "react-hook-form";

type DownloadLinkProps = {
  name: string;
  label: string;
  helpText?: string;
  className?: string;
};

export function DownloadLink({
  name,
  label,
  helpText,
  className,
}: DownloadLinkProps) {
  const { getValues } = useFormContext<{ [key: string]: string }>();
  const showHelpText = helpText;
  const url = getValues(name);

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>

      <div className="relative mt-2">
        {url ? (
          <div className="flex gap-4">
            <a target="_blank" href={url}>
              <span className="flex gap-2">
                <Trans>Download</Trans>
                <ArrowDownTrayIcon className="size-5" />
              </span>
            </a>
            {showHelpText && (
              <div className="relative group">
                <InformationCircleIcon className="size-5 cursor-help text-gray-500" />
                <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block z-10 w-80">
                  <div className="bg-gray-800 text-white text-sm rounded p-2 shadow-lg max-w-80 w-fit">
                    {helpText}
                  </div>
                  <div className="w-3 h-3 bg-gray-800 transform rotate-45 absolute -bottom-1 left-2"></div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <span>-</span>
        )}
      </div>
    </div>
  );
}
