import { Trans, t } from "@lingui/macro";
import { ReservationDetailDTO } from "../../../../types/reservation.dto";
import { SelectBadge } from "../../../components/forms/Select";
import { DateWithTooltip } from "../../../components/elements/DateWithTooltip";
import { useReservationDetailHeader } from "../hooks/useReservationDetailHeader";

type HeaderProps = {
  reservation: ReservationDetailDTO;
};

export function ReservationDetailHeader({ reservation }: HeaderProps) {
  const {
    reservationStatusOptions,
    roomStatusOptions,
    handleReservationStatusChange,
    handleRoomStatusChange,
  } = useReservationDetailHeader(reservation);

  return (
    <div className="p-8">
      <div className="flex flex-wrap space-x-6 items-center">
        <h1 className="text-lg font-semibold text-gray-700">
          {reservation.guestName}
        </h1>
        <div className="">
          <SelectBadge
            options={reservationStatusOptions}
            selected={reservation.reservationStatus}
            onChange={handleReservationStatusChange}
            inlineLabel={t`Status`}
          />
        </div>
      </div>
      <div className="flex flex-wrap space-x-6 text-sm">
        <div className="flex items-center space-x-3 mt-2">
          <div className="text-gray-500">
            {reservation.roomType} - {reservation.room || t`No Room Assigned`}
          </div>
          <SelectBadge
            disabled={reservation.roomDetails === null}
            options={roomStatusOptions}
            selected={reservation.roomDetails?.status}
            onChange={handleRoomStatusChange}
            inlineLabel={t`Room Status`}
            size="xs"
          />
        </div>
        <div className="text-gray-500 mt-2">
          <Trans>
            <span className="font-semibold">Adults</span>:{" "}
            {reservation.numberOfAdults || 0} /{" "}
            <span className="font-semibold">Children</span>:{" "}
            {reservation.numberOfChildren || 0}
          </Trans>
        </div>
        <div className="text-gray-500 mt-2">
          <Trans>
            <span className="font-semibold">In</span>:{" "}
            <DateWithTooltip date={reservation.checkInDate} /> /{" "}
            <span className="font-semibold">Out</span>:{" "}
            <DateWithTooltip date={reservation.checkOutDate} />
          </Trans>
        </div>
      </div>
    </div>
  );
}
