import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { t, Trans } from "@lingui/macro";
import { useRoomCalendarReservation } from "../hooks/useRoomCalendarReservation";
import { useGlobalStore } from "@/store/globalStore";
import { useParams } from "react-router-dom";
import { useRoomCalendarService } from "@/services/useRoomCalendarService";
import { RoomDTO } from "@/types/room.dto";
import { ReservationListItemDTO } from "@/types/reservation.dto";
import { RoomAssignmentDropdown } from "./RoomAssignmentDropdown";
import { TableLoadingOverlay } from "@/components/elements/TableLoadingOverlay";
import { RoomTypeListDTO } from "@/types/roomType.dto";
import {
  AssignmentVisibilityOption,
  ReservationRoomAssignmentFilters,
} from "./ReservationRoomAssignmentFilters";
import dayjs from "dayjs";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { DatePicker } from "@/components/forms/Datepicker";

interface ReservationRoomAssignmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  date: string;
  roomTypeId?: number;
  propertyRoomTypes: RoomTypeListDTO;
}

interface SortState {
  field: keyof ReservationListItemDTO | null;
  direction: "asc" | "desc";
}

export const ReservationRoomAssignmentModal: React.FC<
  ReservationRoomAssignmentModalProps
> = ({ isOpen, onClose, date, roomTypeId, propertyRoomTypes }) => {
  const [currentDate, setCurrentDate] = useState(
    date || dayjs().format("YYYY-MM-DD")
  );
  const params = useParams<{ propertyId: string }>();
  const { getAvailableRoomsForRoomType } = useRoomCalendarService(
    params.propertyId as string
  );
  const [availableRoomsMap, setAvailableRoomsMap] = useState<
    Record<number, RoomDTO[]>
  >({});
  const [selectedRoomTypes, setSelectedRoomTypes] = useState<number[]>(
    roomTypeId ? [roomTypeId] : []
  );
  const [sortState, setSortState] = useState<SortState>({
    field: null,
    direction: "asc",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [visibility, setVisibility] =
    useState<AssignmentVisibilityOption>("all");

  const { reservations, loading, getReservations, setReservations } =
    useRoomCalendarReservation({
      checkInDate: currentDate,
      roomTypeId: selectedRoomTypes,
      shouldExecute: isOpen,
    });

  const fetchAvailableRooms = useCallback(async () => {
    if (!reservations.length) return;

    const roomsMap: Record<number, RoomDTO[]> = {};

    // Fetch rooms for each unique combination of roomTypeId, checkIn, and checkOut dates
    const uniqueReservations = reservations.reduce((acc, res) => {
      const key = `${res.id}-${res.roomTypeId}-${res.checkInDate}-${res.checkOutDate}`;
      if (!acc[key]) {
        acc[key] = res;
      }
      return acc;
    }, {} as Record<string, ReservationListItemDTO>);

    await Promise.all(
      Object.values(uniqueReservations).map(async (reservation) => {
        const rooms = await getAvailableRoomsForRoomType(
          reservation.roomTypeId,
          reservation.checkInDate,
          reservation.checkOutDate
        );
        roomsMap[reservation.id] = rooms;
      })
    );

    setAvailableRoomsMap(roomsMap);
  }, [reservations]);

  useEffect(() => {
    if (date) {
      setCurrentDate(date);
    }

    if (roomTypeId) {
      setSelectedRoomTypes([roomTypeId]);
    }
  }, [date, roomTypeId]);

  useEffect(() => {
    if (isOpen && reservations.length > 0) {
      fetchAvailableRooms();
    }

    // Cleanup function to prevent any pending fetchAvailableRooms calls
    return () => {
      setAvailableRoomsMap({});
    };
  }, [isOpen, reservations, currentDate]);

  const handleAssignmentComplete = async () => {
    await getReservations({
      checkInDate: currentDate,
      roomTypeId: selectedRoomTypes,
      page: "1",
      limit: "100",
    });
  };

  const handleRoomTypeChange = async (newSelection: number[]) => {
    setSelectedRoomTypes(newSelection);
  };

  const handleSort = (field: keyof ReservationListItemDTO) => {
    setSortState((prev) => ({
      field,
      direction:
        prev.field === field && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleDateChange = (newDate: Date) => {
    const formattedDate = dayjs(newDate).format("YYYY-MM-DD");
    setCurrentDate(formattedDate);
  };

  const sortedReservations = useMemo(() => {
    // First filter the reservations
    let filteredReservations = reservations.filter((reservation) => {
      const searchTermLower = searchTerm.toLowerCase();
      const matchesSearch =
        (reservation.bookingId?.toLowerCase().includes(searchTermLower) ??
          false) ||
        (reservation.guestLastName?.toLowerCase().includes(searchTermLower) ??
          false) ||
        (reservation.guestFirstName?.toLowerCase().includes(searchTermLower) ??
          false) ||
        (reservation.roomType?.toLowerCase().includes(searchTermLower) ??
          false);

      // Apply visibility filter
      const matchesVisibility =
        visibility === "all"
          ? true
          : visibility === "assigned"
          ? reservation.roomId !== undefined
          : reservation.roomId === undefined;

      return matchesSearch && matchesVisibility;
    });

    // Then sort the filtered results
    if (!sortState.field) return filteredReservations;

    return [...filteredReservations].sort((a, b) => {
      const direction = sortState.direction === "asc" ? 1 : -1;

      switch (sortState.field) {
        case "guestLastName":
        case "guestFirstName":
        case "roomType":
          return (
            direction *
            String(a[sortState.field]).localeCompare(String(b[sortState.field]))
          );
        case "checkOutDate":
        case "checkInDate":
          return (
            direction *
            (dayjs(a[sortState.field]).unix() -
              dayjs(b[sortState.field]).unix())
          );
        default:
          return 0;
      }
    });
  }, [reservations, sortState, searchTerm, visibility]);

  const renderRoomOptions = (reservation: ReservationListItemDTO) => {
    const availableRooms = availableRoomsMap[reservation.id] || [];

    // Add currently assigned room if it exists and isn't in the list
    if (reservation.roomId && reservation.roomName) {
      const currentRoom = availableRooms.find(
        (room) => room.id === reservation.roomId
      );
      if (!currentRoom) {
        availableRooms.unshift({
          id: reservation.roomId,
          name: reservation.roomName,
        } as RoomDTO);
      }
    }

    return (
      <RoomAssignmentDropdown
        reservation={reservation}
        date={date}
        selectedRoomId={reservation.roomId}
        availableRooms={availableRooms}
        onAssignmentComplete={handleAssignmentComplete}
        loading={loading}
      />
    );
  };

  const collapsed = useGlobalStore((state) => state.sidebarCollapsed);

  const handleClose = () => {
    onClose();
  };

  const handleAfterLeave = () => {
    setSelectedRoomTypes([]);
    setReservations([]);
    setSortState({ field: null, direction: "asc" });
    setSearchTerm("");
    setVisibility("all");
    setCurrentDate("");
    setAvailableRoomsMap({});
  };

  const renderContent = () => {
    if (!reservations.length && !loading) {
      return (
        <div className="flex flex-col items-center justify-center h-48 text-gray-500">
          <p className="text-lg font-medium">
            <Trans>No reservations found</Trans>
          </p>
          <p className="text-sm">
            <Trans>There are no reservations for this date and room type</Trans>
          </p>
        </div>
      );
    }

    return (
      <div className="mt-4">
        <div className="flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 relative">
              {loading && <TableLoadingOverlay />}
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      <a
                        href="#"
                        className="group inline-flex relative"
                        onClick={() => handleSort("bookingId")}
                      >
                        <Trans>Booking ID</Trans>
                        <span
                          className={`absolute -right-5 top-0.5 flex-none rounded ${
                            sortState.field === "bookingId"
                              ? "bg-gray-100 text-gray-900"
                              : "invisible text-gray-400 group-hover:visible group-focus:visible"
                          }`}
                        >
                          <ChevronDownIcon
                            className={`h-4 w-4 ${
                              sortState.direction === "desc" ? "rotate-180" : ""
                            }`}
                            aria-hidden="true"
                          />
                        </span>
                      </a>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <a
                        href="#"
                        className="group inline-flex relative"
                        onClick={() => handleSort("checkInDate")}
                      >
                        <Trans>Check In</Trans>
                        <span
                          className={`absolute -right-5 top-0.5 flex-none rounded ${
                            sortState.field === "checkInDate"
                              ? "bg-gray-100 text-gray-900"
                              : "invisible text-gray-400 group-hover:visible group-focus:visible"
                          }`}
                        >
                          <ChevronDownIcon
                            className={`h-4 w-4 ${
                              sortState.direction === "desc" ? "rotate-180" : ""
                            }`}
                            aria-hidden="true"
                          />
                        </span>
                      </a>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <a
                        href="#"
                        className="group inline-flex relative"
                        onClick={() => handleSort("checkOutDate")}
                      >
                        <Trans>Check Out</Trans>
                        <span
                          className={`absolute -right-5 top-0.5 flex-none rounded ${
                            sortState.field === "checkOutDate"
                              ? "bg-gray-100 text-gray-900"
                              : "invisible text-gray-400 group-hover:visible group-focus:visible"
                          }`}
                        >
                          <ChevronDownIcon
                            className={`h-4 w-4 ${
                              sortState.direction === "desc" ? "rotate-180" : ""
                            }`}
                            aria-hidden="true"
                          />
                        </span>
                      </a>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <a
                        href="#"
                        className="group inline-flex relative"
                        onClick={() => handleSort("guestLastName")}
                      >
                        <Trans>Last Name</Trans>
                        <span
                          className={`absolute -right-5 top-0.5 flex-none rounded ${
                            sortState.field === "guestLastName"
                              ? "bg-gray-100 text-gray-900"
                              : "invisible text-gray-400 group-hover:visible group-focus:visible"
                          }`}
                        >
                          <ChevronDownIcon
                            className={`h-4 w-4 ${
                              sortState.direction === "desc" ? "rotate-180" : ""
                            }`}
                            aria-hidden="true"
                          />
                        </span>
                      </a>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <a
                        href="#"
                        className="group inline-flex relative"
                        onClick={() => handleSort("guestFirstName")}
                      >
                        <Trans>First Name</Trans>
                        <span
                          className={`absolute -right-5 top-0.5 flex-none rounded ${
                            sortState.field === "guestFirstName"
                              ? "bg-gray-100 text-gray-900"
                              : "invisible text-gray-400 group-hover:visible group-focus:visible"
                          }`}
                        >
                          <ChevronDownIcon
                            className={`h-4 w-4 ${
                              sortState.direction === "desc" ? "rotate-180" : ""
                            }`}
                            aria-hidden="true"
                          />
                        </span>
                      </a>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <Trans>Guests</Trans>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <a
                        href="#"
                        className="group inline-flex relative"
                        onClick={() => handleSort("roomType")}
                      >
                        <Trans>Room Type</Trans>
                        <span
                          className={`absolute -right-5 top-0.5 flex-none rounded ${
                            sortState.field === "roomType"
                              ? "bg-gray-100 text-gray-900"
                              : "invisible text-gray-400 group-hover:visible group-focus:visible"
                          }`}
                        >
                          <ChevronDownIcon
                            className={`h-4 w-4 ${
                              sortState.direction === "desc" ? "rotate-180" : ""
                            }`}
                            aria-hidden="true"
                          />
                        </span>
                      </a>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <Trans>Room</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {sortedReservations.map((reservation) => (
                    <tr key={reservation.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
                        <div
                          className="max-w-[150px] truncate"
                          title={reservation.bookingId}
                        >
                          {reservation.bookingId}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {reservation.checkInDate}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {reservation.checkOutDate}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {reservation.guestLastName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {reservation.guestFirstName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {reservation.numberOfGuests}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {reservation.roomType}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        <div
                          className="max-w-[100px] truncate"
                          title={reservation.bookingId}
                        >
                          {renderRoomOptions(reservation)}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={handleAfterLeave}
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div
            className={`flex min-h-full text-center items-center ${
              collapsed ? "justify-center p-0" : "justify-center pl-48"
            }`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-0 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-0 scale-95"
            >
              <Dialog.Panel className="relative transform overflow-y-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-8 w-auto min-w-[1024px] max-w-6xl p-6">
                <div>
                  <Dialog.Title
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    {t`Reservations Room Assignment`}
                  </Dialog.Title>
                  <div className="flex items-center justify-between mb-4 mt-4">
                    <DatePicker
                      selectedDate={
                        currentDate
                          ? dayjs(currentDate).toDate()
                          : new Date(date)
                      }
                      onChange={handleDateChange}
                      positionClass="top-24 left-4"
                    />
                  </div>
                  <ReservationRoomAssignmentFilters
                    roomTypes={propertyRoomTypes}
                    selectedRoomTypes={selectedRoomTypes}
                    onRoomTypeChange={handleRoomTypeChange}
                    onClear={() => handleRoomTypeChange([])}
                    searchTerm={searchTerm}
                    onSearchChange={handleSearchChange}
                    visibility={visibility}
                    onVisibilityChange={setVisibility}
                  />
                  {renderContent()}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
