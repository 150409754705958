import React, { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { SlideOver } from '@/components/slideover/SlideOver';
import { Loading } from '@/components/elements/Loading';
import { useRoomInventoryDetail } from '../hooks/useRoomInventoryDetail';
import { ReservationListItemDTO } from '@/types/reservation.dto';
import { Input } from "@/components/forms/Input/Input";
import { useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@/components/elements/Button';
import { ExclamationTriangleIcon, PlusIcon, MinusIcon, XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import { OVERBOOKED } from '../hooks/useRoomInventoryCalendar';

interface RoomInventoryDetailSlideOverProps {
  date: string;
  roomTypeName: string;
  inventoryId: number;
}

const RoomInventoryDetailSlideOver: React.FC<RoomInventoryDetailSlideOverProps> = ({
  date,
  roomTypeName,
  inventoryId,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedAvailableRooms, setEditedAvailableRooms] = useState<number | null>(null);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const { propertyId } = useParams<{ propertyId: string }>();

  const { roomInventoryDetail, adjustRoomInventoryTotalAvailability } = useRoomInventoryDetail(inventoryId);

  const filteredReservations = roomInventoryDetail?.reservations.filter(reservation => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      (reservation.bookingId?.toLowerCase().includes(searchTermLower) ?? false) ||
      (reservation.guestName?.toLowerCase().includes(searchTermLower) ?? false)
    );
  });

  const handleEditStart = () => {
    setIsEditing(true);
    setEditedAvailableRooms(roomInventoryDetail?.totalAvailableRooms || 0);
  };

  const handleAdjustRooms = (adjustment: number) => {
    if (editedAvailableRooms !== null) {
      setEditedAvailableRooms(Math.max(0, editedAvailableRooms + adjustment));
    }
  };

  const handleSave = async () => {
    if (editedAvailableRooms !== null) {
      await adjustRoomInventoryTotalAvailability(inventoryId, editedAvailableRooms);
    }
    setIsEditing(false);
    setEditedAvailableRooms(null);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedAvailableRooms(null);
  };

  const renderDetails = () => (
    roomInventoryDetail ? (
      <div className="space-y-6">
        <div className="border-b border-gray-200 pb-4 pl-6 pt-4 text-sm">
          <p>
            <Trans><strong>Occupancy Rate:</strong></Trans> {roomInventoryDetail.occupancyRate}%
          </p>
          <div className="space-y-1">
            <p className="flex items-center space-x-2">
              <span>
                <Trans><strong>Available:</strong></Trans>{' '}
                {isEditing ? editedAvailableRooms : roomInventoryDetail.totalAvailableRooms}
              </span>
              {!isEditing ? (
                <button
                  onClick={handleEditStart}
                  className="ml-2 text-blue-600 hover:text-blue-800 text-sm"
                >
                  {t`Edit`}
                </button>
              ) : (
                <div className="flex items-center space-x-2">
                  <div className="flex space-x-1">
                    <button
                      onClick={() => handleAdjustRooms(-1)}
                      className="p-0.5 text-gray-600 hover:text-gray-800 bg-gray-100 rounded"
                    >
                      <MinusIcon className="h-3 w-3" />
                    </button>
                    <button
                      onClick={() => handleAdjustRooms(1)}
                      className="p-0.5 text-gray-600 hover:text-gray-800 bg-gray-100 rounded"
                    >
                      <PlusIcon className="h-3 w-3" />
                    </button>
                  </div>
                  <div className="flex space-x-1">
                    <button
                      onClick={handleSave}
                      disabled={editedAvailableRooms === roomInventoryDetail?.totalAvailableRooms}
                      className={`p-0.5 rounded ${
                        editedAvailableRooms === roomInventoryDetail?.totalAvailableRooms
                          ? 'text-gray-400 bg-gray-100 cursor-not-allowed'
                          : 'text-green-600 hover:text-green-800 bg-green-100'
                      }`}
                    >
                      <CheckIcon className="h-3 w-3" />
                    </button>
                    <button
                      onClick={handleCancel}
                      className="p-0.5 text-red-600 hover:text-red-800 bg-red-100 rounded"
                    >
                      <XMarkIcon className="h-3 w-3" />
                    </button>
                  </div>
                </div>
              )}
            </p>
            {getOverbookingWarning(
              isEditing ? editedAvailableRooms! : roomInventoryDetail.totalAvailableRooms,
              roomInventoryDetail.totalSoldRooms,
              roomInventoryDetail.totalRooms
            ) && (
              <p className="text-red-600 text-xs">
                {getOverbookingWarning(
                  isEditing ? editedAvailableRooms! : roomInventoryDetail.totalAvailableRooms,
                  roomInventoryDetail.totalSoldRooms,
                  roomInventoryDetail.totalRooms
                )}
              </p>
            )}
          </div>
          <p>
            <Trans><strong>Booked:</strong></Trans> {roomInventoryDetail.totalSoldRooms}
          </p>
          <p>
            <Trans><strong>Blocked:</strong></Trans> {roomInventoryDetail.totalBlockedRooms}
          </p>
          <p>
            <Trans><strong>Total:</strong></Trans> {roomInventoryDetail.totalRooms}
          </p>
        </div>
      </div>
    ) : <Loading />
  );

  const renderSearchBox = () => (
    <div className="px-4">
      <Input
        name="reservation-search"
        type="text"
        placeholder={t`Search reservations...`}
        value={searchTerm}
        onChange={handleSearchChange}
      />
    </div>
  );

  const handleViewDetails = (reservationId: number) => {
    window.open(`/properties/${propertyId}/reservations/${reservationId}`, '_blank');
  };

  const renderReservationTable = () => (
    <div className="mx-4 my-6 overflow-hidden border border-gray-200 rounded-lg shadow">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <Trans>Reservation #</Trans>
            </th>
            <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <Trans>Guest Name</Trans>
            </th>
            <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <Trans># of Guests</Trans>
            </th>
            <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <Trans>Check-in Date</Trans>
            </th>
            <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <Trans>Check-out Date</Trans>
            </th>
            <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredReservations?.map((reservation: ReservationListItemDTO) => (
            <tr
              key={reservation.id}
              className={`cursor-pointer hover:bg-gray-50`}
            >
              <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-500">{reservation.bookingId}</td>
              <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-900">{reservation.guestName}</td>
              <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-500">{reservation.numberOfGuests}</td>
              <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-500">{reservation.checkInDate}</td>
              <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-500">{reservation.checkOutDate}</td>
              <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-500">
                <Button onClick={() => handleViewDetails(reservation.id)} type="link" size="xs">{t`View`}</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  const handleClose = () => {
    setOpen(false);
    // Remove inventoryId from URL when closing
    const newParams = new URLSearchParams(searchParams);
    newParams.delete("inventoryId");
    setSearchParams(newParams);
  };

  const renderTitle = () => {
    const hasWarnings = roomInventoryDetail?.message?.message;
    if (!hasWarnings) {
      return `${roomTypeName} - ${date}`;
    }

    const warningType = roomInventoryDetail.message?.message;
    const isOverbooked = warningType === OVERBOOKED;
    
    return (
      <div className="flex items-center space-x-2">
        <ExclamationTriangleIcon 
          className={`h-5 w-5 ${isOverbooked ? 'text-red-600' : 'text-yellow-600'}`} 
        />
        <span>{`${roomTypeName} - ${date}`}</span>
        <span className={`
          px-2 py-1 text-sm rounded-md
          ${isOverbooked ? 'bg-red-100 text-red-700' : 'bg-yellow-100 text-yellow-700'}
        `}>
          {warningType}
        </span>
      </div>
    );
  };

  const getOverbookingWarning = (totalAvailableRooms: number, totalSoldRooms: number, totalRooms: number): string | null => {
    if (totalAvailableRooms + totalSoldRooms > totalRooms) {
      return t`Available ${totalAvailableRooms} and Booked ${totalSoldRooms} exceeds total room capacity`;
    }
    return null;
  };

  return (
    <SlideOver
      open={open}
      hideCancelButton={true}
      hideSaveButton={true}
      onClose={handleClose}
      title={renderTitle()}
      content={
        <div className="space-y-6">
          {renderDetails()}
          {renderSearchBox()}
          {renderReservationTable()}
        </div>
      }
    />
  );
};

export default RoomInventoryDetailSlideOver; 