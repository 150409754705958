import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { Trans } from '@lingui/macro';

interface CellActionMenuProps {
  onCreateEvent: () => void;
  onCreateReservation: () => void;
  onAssignReservation: () => void;
}

export const CellActionMenu: React.FC<CellActionMenuProps> = ({ onCreateEvent, onCreateReservation, onAssignReservation }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showAbove, setShowAbove] = useState(false);
  const [showLeft, setShowLeft] = useState(false);
  const cellRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    if (!isOpen) {
      // Check position before opening
      const cellRect = cellRef.current?.getBoundingClientRect();
      const menuHeight = 120; // Approximate height of menu
      const menuWidth = 224; // Width of menu (w-56 = 14rem = 224px)
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      
      if (cellRect) {
        const spaceBelow = windowHeight - cellRect.bottom;
        const spaceRight = windowWidth - cellRect.right;
        
        setShowAbove(spaceBelow < menuHeight);
        setShowLeft(spaceRight < menuWidth);
      }
    }
    setIsOpen(!isOpen);
  };

  const handleItemClick = (action: () => void) => {
    action();
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cellRef.current && !cellRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  return (
    <div 
      ref={cellRef}
      className="relative w-full h-full group"
      onClick={toggleMenu}
    >
      <div 
        className="w-full h-full cursor-pointer transition-colors duration-200 ease-in-out group-hover:bg-gray-100"
        aria-haspopup="true"
        aria-expanded={isOpen}
      />

      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div 
          ref={menuRef}
          className={`absolute ${showAbove ? 'bottom-full mb-0' : 'top-0 mt-1'} ${showLeft ? 'right-0' : 'left-24'} z-20 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
          role="menu"
          aria-orientation="vertical"
        >
          <div className="py-1">
            <button
              className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              onClick={() => handleItemClick(onCreateEvent)}
            >
              <Trans>Create new event</Trans>
            </button>
            <button
              className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              onClick={() => handleItemClick(onCreateReservation)}
            >
              <Trans>Create new reservation</Trans>
            </button>
            <button
              className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              onClick={() => handleItemClick(onAssignReservation)}
            >
              <Trans>Assign reservation</Trans>
            </button>
          </div>
        </div>
      </Transition>
    </div>
  );
};

