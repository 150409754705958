import { useState, useRef } from 'react';
import { useGlobalStore } from '@/store/globalStore';

const collapsedSidebarThreshold = 150;

export const useSidebar = () => {
  const [width, setWidth] = useState<number>(220);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const delayTimer = useRef<NodeJS.Timeout | null>(null);
  const isHovered = useRef<boolean>(false);

  const collapsed = useGlobalStore((state) => state.sidebarCollapsed);
  const peeked = useGlobalStore((state) => state.sidebarPeeked);
  const setSidebarCollapsed = useGlobalStore((state) => state.setSidebarCollapsed);
  const setSidebarPeeked = useGlobalStore((state) => state.setSidebarPeeked);

  const toggleSidebar = () => setSidebarCollapsed(!collapsed);

  const handleMouseOver = () => {
    if (collapsed) {
      isHovered.current = true;
      setSidebarPeeked(true);
    }
  };

  const handleMouseOut = () => {
    isHovered.current = false;
    delayTimer.current = setTimeout(() => {
      if (collapsed && !isHovered.current) {
        setSidebarPeeked(false);
      }
    }, 500);
  };

  const resizeSidebar = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const oldWidth = width;
    const onMouseMove = (e: MouseEvent) => {
      setWidth(e.clientX);
      setIsResizing(true);
    };
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener(
      "mouseup",
      (e: MouseEvent) => {
        if (e.clientX < collapsedSidebarThreshold) {
          setWidth(oldWidth);
          setSidebarCollapsed(true);
        }
        setIsResizing(false);
        document.removeEventListener("mousemove", onMouseMove);
      },
      { once: true }
    );
  };

  return {
    width,
    collapsed,
    peeked,
    isResizing,
    handleMouseOver,
    handleMouseOut,
    toggleSidebar,
    resizeSidebar
  };
};

