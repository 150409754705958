import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { TablePaginationConfig } from "antd";

import { useReservationService } from "@/services/useReservationService";
import { GlobalState, useGlobalStore } from "@/store/globalStore";
import {
  ReservationState,
  useReservationStore,
} from "@/store/reservationStore";
import { ReservationsQueryParam } from "@/api/createReservationClient";

import { defaultReservationQueryParam } from "../constants";

export const useReservationList = () => {
  const params = useParams<{ propertyId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const reservations = useReservationStore(
    (state: ReservationState) => state.reservations
  );
  const pagination = useReservationStore(
    (state: ReservationState) => state.pagination
  );

  const loading = useGlobalStore((state: GlobalState) => state.loading);
  const { getReservations } = useReservationService(
    params.propertyId as string
  );

  const queryParams = searchParams.toString();

  useEffect(() => {
    if (params.propertyId) {
      if (queryParams) {
        const parsedQueryParams = queryString.parse(queryParams);
        getReservations({
          ...(parsedQueryParams as ReservationsQueryParam),
        });
      } else {
        setSearchParams(queryString.stringify(defaultReservationQueryParam));
      }
    }
  }, [params.propertyId, queryParams]); // eslint-disable-line

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    if (pagination.current && pagination.pageSize) {
      const currentFilterParams = queryString.parse(
        queryParams
      ) as ReservationsQueryParam;
      const filterParams: ReservationsQueryParam = {
        ...currentFilterParams,
        page: pagination.current.toString(),
        limit: pagination.pageSize.toString(),
      };

      setSearchParams(queryString.stringify(filterParams));
    }
  };

  return { reservations, pagination, loading, handleTableChange };
};
