import { LoadingSpinner } from '@/components/elements/LoadingSpinner';
import { t } from '@lingui/macro';

interface CalendarLoadingIndicatorProps {
  message?: string;
  className?: string;
}

export const CalendarLoadingIndicator: React.FC<CalendarLoadingIndicatorProps> = ({ 
  message = t`Loading calendar data`,
  className = ''
}) => {
  return (
    <div className={`flex items-center justify-center px-2 text-gray-500 text-sm ${className}`}>
      <LoadingSpinner size="sm" className="mr-2" />
      <span>{message}</span>
    </div>
  );
};
