import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { t } from '@lingui/macro';

import { useGlobalStore } from '@/store/globalStore';
import { useReservationService } from '@/services/useReservationService';
import { getTranslatedName } from '@/utils';
import { useRoomService } from '@/services/useRoomService';
import { useNotificationStore } from '@/store/notificationStore';
import { useRoomStore } from '@/store/roomStore';
import { useUserStore } from '@/store/userStore';
import { CreateReservationDTO } from '@/types/reservation.dto';
import { useNavigate } from 'react-router-dom';

interface UseReservationFormProps {
  checkInDate?: string;
  roomTypeId?: number;
  roomId?: number;
}

export const useReservationForm = ({
  checkInDate,
  roomTypeId,
  roomId,
}: UseReservationFormProps) => {
  const propertyId = useGlobalStore((state) => state.currentProperty);
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const addNotification = useNotificationStore((state) => state.addNotification);
  const { roomTypes } = useRoomStore();
  const { getRoomTypes } = useRoomService(propertyId);

  const methods = useForm<CreateReservationDTO>({
    defaultValues: {
      checkInDate: checkInDate || "",
      checkOutDate: "",
      adults: 1,
      children: 0,
      rooms: 1,
      roomTypeId: roomTypeId || undefined,
      bookingPrice: 0,
      pointsUsed: 0,
      guests: [],
      roomId: roomId || undefined,
    }
  });

  const { addReservation } = useReservationService(propertyId, methods);

  useEffect(() => {
    if (!roomTypes.length) {
      getRoomTypes();
    }
  }, [roomTypes, getRoomTypes]);

  const roomTypeOptions = useMemo(
    () =>
      user
        ? roomTypes.map((roomType) => ({
            label: getTranslatedName(roomType, user.locale),
            value: roomType.id,
          }))
        : [],
    [roomTypes, user]
  );

  const onSubmit = async (data: CreateReservationDTO) => {
    methods.clearErrors(); // Clear previous errors
    const originalData = data;
    try {
      const success = await addReservation(data);
      if (success) {
        addNotification({
          title: t`New reservation created`,
          type: 'success',
          timeoutMs: 1500,
        });
        navigate('..');
      }
    } catch (e: unknown) {
      addNotification({
        title: t`Unable to create reservation`,
        type: 'error',
        message: String(e),
        timeoutMs: 1500,
      });
      methods.reset(originalData);
    }
  };

  return {
    methods,
    roomTypeOptions,
    onSubmit,
  };
};
