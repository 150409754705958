import { useEffect, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { t } from "@lingui/macro";

import { ReservationDetailDTO, UpdateReservationDTO } from "@/types/reservation.dto";
import { StackedInputGroup } from "@/components/forms/Input";
import { StackedSelectInputGroup } from "@/components/forms/Select";
import { Button } from "@/components/elements/Button/Button";
import { useReservationService } from "@/services/useReservationService";
import { useCommonService } from "@/services/useCommonService";
import { useOtaService } from "@/services/useOtaService";
import { useGlobalStore } from "@/store/globalStore";
import { useNotificationStore } from "@/store/notificationStore";
import { useOtaStore, OTAState } from "@/store/otaStore";
import { Title } from "@/components/elements/typography/Title";

interface ReservationInfoProps {
  reservation: ReservationDetailDTO;
}

export function ReservationInfo({ reservation }: ReservationInfoProps) {
  const methods = useForm<UpdateReservationDTO>();
  
  const propertyId = useGlobalStore((state) => state.currentProperty);
  const addNotification = useNotificationStore((state) => state.addNotification);
  const reservationStatuses = useGlobalStore((state) => state.reservationStatuses);
  const otas = useOtaStore((state: OTAState) => state.otas);
  
  const { getReservationStatuses } = useCommonService();
  const { getOtas } = useOtaService(propertyId);
  const { updateReservation } = useReservationService(propertyId, methods);

  // Load reservation statuses
  useEffect(() => {
    if (reservationStatuses.length === 0) {
      getReservationStatuses();
    }
  }, [reservationStatuses.length]);

  // Load OTAs
  useEffect(() => {
    if (propertyId) {
      getOtas();
    }
  }, []);

  // Initialize form values when data is available
  useEffect(() => {
    if (otas.length && reservationStatuses.length) {
      methods.reset({
        id: reservation.id,
        booker: reservation.representative?.id,
        bookingDate: reservation.bookingDate,
        checkInDate: reservation.checkInDate,
        checkOutDate: reservation.checkOutDate,
        reservationStatusId: reservationStatuses.length > 0 
          ? reservationStatuses.find(status => status.label === reservation.reservationStatus)?.id 
          : undefined,
        otaId: reservation.otaId,
        otaPlanId: reservation.planId,
      });
    }
  }, [otas.length, reservationStatuses.length]);

  // Memoized select options
  const reservationStatusOptions = useMemo(() => 
    reservationStatuses.map(status => ({
      label: status.label,
      value: status.id
    })),
    [reservationStatuses]
  );

  const otaOptions = useMemo(() => 
    otas.map(ota => ({
      label: ota.name,
      value: ota.id,
    })),
    [otas]
  );

  // Form submission handler
  const onSubmit = async (data: UpdateReservationDTO) => {
    try {
      await updateReservation(data);
      addNotification({
        title: t`Reservation updated successfully`,
        type: 'success',
        timeoutMs: 1500,
      });
    } catch (error) {
      addNotification({
        title: t`Failed to update reservation`,
        type: 'error',
        message: String(error),
        timeoutMs: 1500,
      });
    }
  };

  return (
    <div className="rounded-lg bg-white border-gray-100 border mx-8">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-6 p-4">
          {/* Form Fields */}
          <div className="grid grid-cols-2 gap-4">
            <StackedInputGroup
              label={t`Booker ID`}
              name="booker"
              type="number"
            />
            <StackedInputGroup
              label={t`Booking Date`}
              name="bookingDate"
              type="date"
            />
            <StackedInputGroup
              label={t`Check In Date`}
              name="checkInDate"
              type="date"
            />
            <StackedInputGroup
              label={t`Check Out Date`}
              name="checkOutDate"
              type="date"
            />
            <StackedSelectInputGroup
              label={t`Reservation Status`}
              selectProps={{
                name: "reservationStatusId",
                options: reservationStatusOptions
              }}
            />
            <StackedSelectInputGroup
              label={t`OTA`}
              selectProps={{
                name: "otaId",
                options: otaOptions
              }}
            />
          </div>
          
          {/* Submit Button */}
          <div className="flex justify-end">
            <Button
              type="primary"
              loading={methods.formState.isSubmitting}
            >
              {t`Update Reservation`}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
