import { Title } from "@/components/elements/typography/Title";

type PlaceholderTabProps = {
  title: string;
};

export function PlaceholderTab({ title }: PlaceholderTabProps) {
  return (
    <div className="py-4">
      <Title level={4}>{title}</Title>
      <p className="text-gray-500 mt-2">This section is under development.</p>
    </div>
  );
}
