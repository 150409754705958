import { AddOptionToReservationDTO, CreateReservationDTO, ReservationDetailDTO, ReservationListDTO, UpdateReservationDTO } from "../../types/reservation.dto";
import { AllocateRoomDTO, SingleReservationRoomAllocationDTO } from "../../types/roomAllocation.dto";
import { AddReservationMemoDTO, AddReservationTopicDTO, UpdateReservationMemoDTO, UpdateReservationMemoTopicDTO } from "../../types/reservationMemo.dto"
import { apiClient } from "./createApiClient";
import { CreateInvoiceItemDTO, UpdateInvoiceItemDTO } from "../../types/invoiceItem.dto";
import { AddReservationGuestDTO, UpdateReservationGuestDTO, ReservationGuestDTO, ReservationGuestListDTO } from "../../types/guest.dto";
import { AddReservationSurveyResponseDTO } from "../../types/surveyResponse.dto";
import { ReservationTaskListAndCountDTO } from "../../types/reservationTask.dto"

type ReservationsResponse = {
  message: string;
  reservations: ReservationListDTO;
  pagination: {
    currentPage: number;
    pageCount: number;
    total: number;
    nextPageAvailable: boolean;
  }
}

type ReservationResponse = {
  message: string;
  reservation: ReservationDetailDTO;
}

type RoomAllocationData = {
  message: string;
  roomAllocationData: SingleReservationRoomAllocationDTO;
}

export type ReservationsQueryParam = {
  page: string;
  limit: string;
  checkOutDateStart?: string;
  checkOutDateEnd?: string;
  checkInDateStart?: string;
  checkInDateEnd?: string;
  bookingDateStart?: string;
  bookingDateEnd?: string;
  checkInDate?: string;
  checkOutDate?: string;
  otas?: string[];
  temairazuOTABookingId?: string;
  reservationStatus?: string;
  paymentStatus?: string;
  roomTypeId?: string | string[] | number | number[] | any;
}

export type ReservationRoomAllocationQueryParam = {
  roomTypeId: number;
  checkInDate: string;
  checkOutDate: string;
}

export type GenericHTTPResponse = {
  message: string;
  error?: string[];
}

export function createReservationClient(propertyId: string) {
  const baseUrl = `/property/${propertyId}/reservations`;

  const createReservation = (
    data: CreateReservationDTO
  ) => 
    apiClient.post<CreateReservationDTO, ReservationResponse>(baseUrl, data);

  const getReservations = (
    data: ReservationsQueryParam,
  ) =>
    apiClient.get<ReservationsResponse>(
      baseUrl, data,
    );

  const getReservation = (
    reservationId: string
  ) =>
    apiClient.get<ReservationResponse>(`${baseUrl}/${reservationId}`);

  const updateReservation = (
    reservation: UpdateReservationDTO,
  ) =>
    apiClient.patch<UpdateReservationDTO, ReservationResponse>(`${baseUrl}/${reservation.id}`, reservation);

  const deleteReservation = (
    reservationId: string,
  ) =>
    apiClient.delete(`${baseUrl}/${reservationId}`);

  const getRoomAllocationDataForReservation = (
    reservationId: number,
    data: ReservationRoomAllocationQueryParam,
  ) =>
    apiClient.get<RoomAllocationData>(`${baseUrl}/${reservationId}/room-allocation`, data);

  const allocateRoomForReservation = (
    reservationId: number,
    data: AllocateRoomDTO,
  ) =>
    apiClient.post<AllocateRoomDTO>(`${baseUrl}/${reservationId}/room-allocation`, data);

  const removeRoomAllocation = (
    reservationId: number,
  ) =>
    apiClient.get(`${baseUrl}/${reservationId}/room-allocation/remove`)

  const linkReservation = (
    reservationId: number,
    data: number[],
  ) =>
    apiClient.post<{ linkedReservations: number[] }, ReservationResponse>(`${baseUrl}/${reservationId}/link-reservation`, { linkedReservations: data });

  const addOptionToReservation = (
    data: AddOptionToReservationDTO,
  ) =>
    apiClient.post<AddOptionToReservationDTO, { message: string; }>(`${baseUrl}/${data.reservationId}/option`, data)

  const addReservationMemoTopic = (
    data: AddReservationTopicDTO,
  ) =>
    apiClient.post<AddReservationTopicDTO, { message: string; error?: string[] | string}>(
      `${baseUrl}/${data.reservationId}/topics`,
      data,
    );

  const updateReservationMemoTopic = (
    reservationId: number,
    data: UpdateReservationMemoTopicDTO,
  ) =>
    apiClient.patch<UpdateReservationMemoTopicDTO, { message: string; error?: string[] | string}>(
      `${baseUrl}/${reservationId}/topics/${data.id}`,
      data,
    );

  const deleteReservationMemoTopic = (
    reservationId: number,
    topicId: number
  ) =>
    apiClient.delete<{ message: string; error?: string[] | string}>(
      `${baseUrl}/${reservationId}/topics/${topicId}`,
    );

  const addReservationMemo = (
    reservationId: number,
    data: AddReservationMemoDTO,
  ) =>
    apiClient.post<AddReservationMemoDTO, { message: string, error?: string[] | string }>(
      `${baseUrl}/${reservationId}/topics/${data.topicId}/memos`,
      data,
    )

  const updateReservationMemo = (
    reservationId: number,
    topicId: number,
    data: UpdateReservationMemoDTO,
  ) =>
    apiClient.patch<UpdateReservationMemoDTO, { message: string, error?: string[] | string }>(
      `${baseUrl}/${reservationId}/topics/${topicId}/memos/${data.id}`,
      data,
    );

  const deleteReservationMemo = (
    reservationId: number,
    topicId: number,
    memoId: number,
  ) =>
    apiClient.delete<{message: string}>(`${baseUrl}/${reservationId}/topics/${topicId}/memos/${memoId}`);

  const addReservationInvoiceItem = (
    reservationId: number,
    invoiceId: number,
    data: CreateInvoiceItemDTO,
  ) =>
    apiClient.post<CreateInvoiceItemDTO, { message: string; }>(
      `${baseUrl}/${reservationId}/invoices/${invoiceId}/items`,
      data
    );

  const updateReservationInvoiceItem = (
    reservationId: number,
    data: UpdateInvoiceItemDTO,
  ) =>
    apiClient.patch<UpdateInvoiceItemDTO, { message: string; }>(
      `${baseUrl}/${reservationId}/invoice-items/${data.id}`,
      data,
    );

  const deleteReservationInvoiceItem = (
    reservationId: number,
    invoiceItemId: number,
  ) =>
    apiClient.delete<{ message: string; }>(
      `${baseUrl}/${reservationId}/invoice-items/${invoiceItemId}`,
    );

  const getReservationGuests = (
    reservationId: number,
  ) =>
    apiClient.get<{ message: string; guests: ReservationGuestListDTO; count: number; }>(
      `${baseUrl}/${reservationId}/guests`,
    );

  const getReservationGuest = (
    reservationId: number,
    guestId: number,
  ) =>
    apiClient.get<{ message: string; guest: ReservationGuestDTO; }>(
      `${baseUrl}/${reservationId}/guests/${guestId}`,
    );
  
  const addReservationGuest = (
    reservationId: number,
    data: AddReservationGuestDTO,
  ) =>
    apiClient.post<any, { message: string; }>(
      `${baseUrl}/${reservationId}/guests`,
      data,
    );

  const updateReservationGuest = (
    reservationId: number,
    data: UpdateReservationGuestDTO,
  ) =>
    apiClient.patch<any, { message: string; }>(
      `${baseUrl}/${reservationId}/guests/${data.id}`,
      data,
    );

  const deleteReservationGuest = (
    reservationId: number,
    guestId: number,
  ) =>
    apiClient.delete<{ message: string; }>(
      `${baseUrl}/${reservationId}/guests/${guestId}`,
    );

  const updateReservationSurveyResponse = (
    reservationId: number,
    data: AddReservationSurveyResponseDTO,
  ) =>
    apiClient.post<any, { message: string; }>(
      `${baseUrl}/${reservationId}/surveys`,
      data,
    );

  const getReservationSurveyResponse = (
    reservationId: number,
  ) =>
    apiClient.get<{ message: string; surveyResponse: any; }>(
      `${baseUrl}/${reservationId}/surveys`,
    );

  // RESERVATION TASK
  const reservationTaskBaseUrl = (reservationId: number) => `${baseUrl}/${reservationId}/tasks`;
  const getReservationTasks = (
    reservationId: number,
  ) =>
    apiClient.get<{ message: string, tasks: ReservationTaskListAndCountDTO}>(`${reservationTaskBaseUrl(reservationId)}`);

  const createReservationTasks = (
    reservationId: number,
  ) =>
    apiClient.post<{}, { message: string, tasks: ReservationTaskListAndCountDTO}>(`${reservationTaskBaseUrl(reservationId)}/create`);

  const updateReservationTask = (reservationId: number, taskId: number, completed: boolean) =>
    apiClient.patch<{ completed: boolean }, { message: string, tasks: ReservationTaskListAndCountDTO }>(
      `${reservationTaskBaseUrl(reservationId)}/${taskId}`, { completed }
    );

  const resetReservationTasks = (reservationId: number) =>
    apiClient.put<{}, { message: string, tasks: ReservationTaskListAndCountDTO }>(
      `${reservationTaskBaseUrl(reservationId)}/reset`, {}
    );

  const completeReservation = (reservationId: number) =>
    apiClient.post<{}, { message: string }>(
      `${reservationTaskBaseUrl(reservationId)}/complete`, {}
    );

  return {
    createReservation,
    getReservation,
    getReservations,
    linkReservation,
    updateReservation,
    deleteReservation,

    addReservationMemoTopic,
    updateReservationMemoTopic,
    deleteReservationMemoTopic,
    addReservationMemo,
    updateReservationMemo,
    deleteReservationMemo,

    getRoomAllocationDataForReservation,
    allocateRoomForReservation,
    removeRoomAllocation,

    addOptionToReservation,
    addReservationInvoiceItem,
    updateReservationInvoiceItem,
    deleteReservationInvoiceItem,

    getReservationGuests,
    getReservationGuest,
    addReservationGuest,
    updateReservationGuest,
    deleteReservationGuest,

    getReservationSurveyResponse,
    updateReservationSurveyResponse,

    getReservationTasks,
    createReservationTasks,
    updateReservationTask,
    resetReservationTasks,
    completeReservation,
  }
}
