import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, CalendarIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { t, Trans } from '@lingui/macro';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

interface DatePickerProps {
  selectedDate: Date;
  onChange: (date: Date) => void;
  toggleText?: string;
  positionClass?: string;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  selectedDate,
  onChange,
  toggleText = "",
  positionClass = "top-full",
}) => {
  const [currentMonth, setCurrentMonth] = useState(dayjs(selectedDate));
  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const nextMonth = () => setCurrentMonth(currentMonth.add(1, 'month'));
  const prevMonth = () => setCurrentMonth(currentMonth.subtract(1, 'month'));
  const nextDay = () => onChange(dayjs(selectedDate).add(1, 'day').toDate());
  const prevDay = () => onChange(dayjs(selectedDate).subtract(1, 'day').toDate());

  const daysInMonth = () => {
    const start = currentMonth.startOf('month');
    const end = currentMonth.endOf('month');
    const days = [];
    let day = start;

    while (day.isSameOrBefore(end)) {
      days.push(day);
      day = day.add(1, 'day');
    }

    return days;
  };

  const selectDate = (day: dayjs.Dayjs) => {
    onChange(day.toDate());
    setIsOpen(false);
  };

  const toggleDatePicker = () => setIsOpen(!isOpen);

  const goToToday = () => {
    const today = dayjs();
    setCurrentMonth(today);
    onChange(today.toDate());
    setIsOpen(false);
  };

  return (
    <div className="inline-flex items-center justify-center text-xs" ref={datePickerRef}>
      <button
        onClick={prevDay}
        className="p-1 rounded-l-md border border-r-0 border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-indigo-500"
      >
        <ChevronLeftIcon className="h-4 w-4" />
      </button>
      <div>
        <button
          type="button"
          onClick={toggleDatePicker}
          className="inline-flex justify-center rounded-none border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-indigo-500"
        >
          <CalendarIcon className="mr-1 h-4 w-4 text-gray-400" aria-hidden="true" />
          <span className="hidden lg:inline-block">{dayjs(selectedDate).format("MMM D, YYYY")}</span>
          <span className="inline-block lg:hidden">{dayjs(selectedDate).format("MMM D")}</span>
        </button>
      </div>
      <button
        onClick={nextDay}
        className="p-1 rounded-r-md border border-l-0 border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-indigo-500"
      >
        <ChevronRightIcon className="h-4 w-4" />
      </button>

      {isOpen && (
        <div className={`absolute ${positionClass} left-0 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50`}>
          <div className="p-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <div className="flex items-center justify-between mb-1">
              <button onClick={prevMonth} className="p-1 rounded-full hover:bg-gray-100">
                <ChevronLeftIcon className="w-4 h-4" />
              </button>
              <h2 className="text-sm font-semibold">
                {currentMonth.format('MMM YYYY')}
              </h2>
              <button onClick={nextMonth} className="p-1 rounded-full hover:bg-gray-100">
                <ChevronRightIcon className="w-4 h-4" />
              </button>
            </div>
            <div className="grid grid-cols-7 gap-1 mb-1">
              {[t`S`, t`M`, t`T`, t`W`, t`T`, t`F`, t`S`].map((day, index) => (
                <div key={`${day}-${index}`} className="text-center text-xs font-medium text-gray-500">
                  {day}
                </div>
              ))}
            </div>
            <div className="grid grid-cols-7 gap-1">
              {daysInMonth().map((day, index) => (
                <button
                  key={`${day.toString()}-${index}`}
                  onClick={() => selectDate(day)}
                  className={`
                    p-1 rounded-full text-xs
                    ${!day.isSame(currentMonth, 'month') && 'text-gray-300'}
                    ${day.isSame(currentMonth, 'month') && !day.isSame(selectedDate, 'day') && 'hover:bg-gray-100'}
                    ${day.isSame(selectedDate, 'day') && 'bg-blue-500 text-white hover:bg-blue-600'}
                    ${day.isSame(dayjs(), 'day') && !day.isSame(selectedDate, 'day') && 'border border-blue-500'}
                  `}
                >
                  {day.format('D')}
                </button>
              ))}
            </div>
            <div className="mt-1 flex justify-end">
              <button
                onClick={goToToday}
                className="px-2 py-1 text-xs text-blue-600 hover:bg-blue-100 rounded"
              >
                <Trans>Today</Trans>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;