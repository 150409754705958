import { RegisterOptions } from "react-hook-form";
import { Trans } from "@lingui/macro";
import { useFormContext } from "react-hook-form";
import { getNestedValue } from "../../../utils";
import { SelectOption } from "./types";

export type StackedSelectInputGroupProps = {
  label: string;
  selectProps: {
    name: string;
    defaultValue?: string | number | any;
    options: SelectOption[];
  };
  helpText?: string;
  className?: string;
  options?: RegisterOptions;
  required?: boolean;
};

export function StackedSelectInputGroup({
  label,
  helpText,
  selectProps,
  className,
  options,
  required = false,
}: StackedSelectInputGroupProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext<{ [key: string]: string }>();
  const name = selectProps.name;
  const error = getNestedValue(errors, name)?.message;
  const showHelpText = helpText && !error;

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>
      <select
        id={name}
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-gray-600 text-sm leading-6"
        defaultValue={selectProps?.defaultValue || ""}
        {...register(name, options)}
      >
        {!selectProps.defaultValue && (
          <option value="" disabled hidden>
            <Trans>Select an option</Trans>
          </option>
        )}
        {selectProps.options.map((option, idx) => (
          <option key={idx} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {error}
        </p>
      )}
      {showHelpText && (
        <p className="mt-2 text-sm text-gray-500" id={`${name}-description`}>
          {helpText}
        </p>
      )}
    </div>
  );
}
