import {
  ReservationDetailDTO,
  UpdateReservationDTO,
} from "@/types/reservation.dto";
import { StackedInputGroup } from "@/components/forms/Input";
import { t, Trans } from "@lingui/macro";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "@/components/elements/Button";
import { Title } from "@/components/elements/typography/Title";
import { DownloadLink } from "@/components/forms/DownloadLink";

type CashierInfoProps = {
  reservation: ReservationDetailDTO;
};

export function CashierInfo({ reservation }: CashierInfoProps) {
  const methods = useForm<UpdateReservationDTO>();

  const onSubmit = async (data: UpdateReservationDTO) => {
    console.log("Submitted", data);
  };

  return (
    <div className="rounded-lg bg-white border-gray-100 border mx-8">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="space-y-6 p-4"
        >
          {/* Form Fields */}
          <div className="grid grid-cols-2 gap-4">
            <StackedInputGroup
              label={t`Method of Payment`}
              name="comment"
              type="text"
              options={{ disabled: true }}
            />
          </div>
          <Title level={2}>
            <Trans>Documents</Trans>
          </Title>
          <div className="grid grid-cols-2 gap-4">
            <DownloadLink label={t`Ryoshusho Information`} name="ryoshusho" />
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <Button type="primary">{t`Update Reservation`}</Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
