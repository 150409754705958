import React, { useEffect, useRef, useState } from 'react';
import { SummaryInventoryResponseDTO } from '@/types/roomInventory.dto';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { t, Trans } from '@lingui/macro';
import dayjs from 'dayjs';
import { OVERBOOKED } from '../hooks/useRoomInventoryCalendar';

interface RoomInventoryDailySummaryPopupProps {
  summary: SummaryInventoryResponseDTO;
  date: string;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  onRoomTypeClick: (inventoryId: number) => void;
}

export const RoomInventoryDailySummaryPopup: React.FC<RoomInventoryDailySummaryPopupProps> = ({
  summary,
  date,
  onClose,
  anchorEl,
  onRoomTypeClick,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const [popupStyle, setPopupStyle] = useState<{[k: string]: string}>({ visibility: 'hidden' });

  const updatePopupPosition = () => {
    if (anchorEl && popupRef.current) {
      const anchorRect = anchorEl.getBoundingClientRect();
      const popupRect = popupRef.current.getBoundingClientRect();
      
      let top = anchorRect.bottom + window.scrollY;
      let left = anchorRect.left + window.scrollX;

      // Adjust position if popup would go off screen
      if (top + popupRect.height > window.innerHeight) {
        top = anchorRect.top - popupRect.height + window.scrollY;
      }
      if (left + popupRect.width > window.innerWidth) {
        left = anchorRect.right - popupRect.width + window.scrollX;
      }

      setPopupStyle({
        top: `${top}px`,
        left: `${left}px`,
        visibility: 'visible',
      });
    }
  };

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node) && event.target !== anchorEl) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscKey);
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', updatePopupPosition);
    window.addEventListener('scroll', updatePopupPosition);

    updatePopupPosition();

    return () => {
      document.removeEventListener('keydown', handleEscKey);
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', updatePopupPosition);
      window.removeEventListener('scroll', updatePopupPosition);
    };
  }, [onClose, anchorEl]);


  const handleRoomTypeClick = (inventoryId: number) => {
    onRoomTypeClick(inventoryId);
    onClose();
  };

  const dailySummary = summary[date];
  if (!dailySummary) return null;

  return (
    <>
      {/* Add overlay div that covers the entire screen */}
      {anchorEl && (
        <div
          className="fixed inset-0 z-40"
          onClick={onClose}
        />
      )}
      <div 
        ref={popupRef} 
        style={{...popupStyle, position: "fixed"}}
        className="bg-white rounded-lg shadow-lg p-4 min-w-[240px] max-w-md z-50 border border-gray-200"
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
          aria-label="Close"
        >
          <XMarkIcon className="h-5 w-5" />
        </button>

        <h3 className="text-md font-semibold mb-2">
          {t`Summary - ${dayjs(date).format('MMM DD, YYYY')}`}
        </h3>

        {/* Warnings */}
        {Object.keys(dailySummary.warnings).map((warning) => {
          return (
            <div key={warning} className="mb-2">
              <div className={`text-sm font-medium ${warning === OVERBOOKED ? 'text-red-700' : 'text-yellow-700'}`}>{warning}</div>
              {dailySummary.warnings[warning].map((warning, idx) => (
                <div key={idx} className="text-sm ml-2 flex justify-between items-center">
                  <span>• {warning.roomTypeName}</span>
                  <button
                    onClick={() => handleRoomTypeClick(warning.inventoryId)}
                    className="ml-2 text-xs text-blue-600 hover:text-blue-800"
                  >
                    {t`View Details`}
                  </button>
                </div>
              ))}
            </div>
          );
        })}

        {/* Stats */}
        <div className="space-y-1">
          <p className="text-sm text-gray-600">
            <Trans>
              <span className="font-medium">Occupancy Rate:</span>{' '}
              {dailySummary.occupancyRate}%
            </Trans>
          </p>
          <p className="text-sm text-gray-600">
            <Trans>
              <span className="font-medium">Available Rooms:</span>{' '}
              {dailySummary.totalAvailableRooms}
            </Trans>
          </p>
          <p className="text-sm text-gray-600">
            <Trans>
              <span className="font-medium">Booked Rooms:</span>{' '}
              {dailySummary.totalSoldRooms}
            </Trans>
          </p>
          <p className="text-sm text-gray-600">
            <Trans>
              <span className="font-medium">Total Rooms:</span>{' '}
              {dailySummary.totalRooms}
            </Trans>
          </p>
        </div>
      </div>
    </>
  );
}; 