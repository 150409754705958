import { DailyAllocationSummary, RoomListDTO, RoomListDTOForCalendar } from "@/types/room.dto"
import { BedType } from "@/constants/common/bedTypes";
import { RoomStatus } from "@/constants/common/rooms";
import { BaseResponse } from "./utils";
import { apiClient, GenericHTTPResponse } from "./createApiClient";
import { CreateRoomEventDTO, UpdateRoomEventDTO } from "@/types/roomEvent.dto";
import { RoomTypeListDTO } from "@/types/roomType.dto";
import { ReservationListDTO } from "@/types/reservation.dto";
import { AllocateRoomDTO, RemoveRoomAssignmentDTO } from "@/types/roomAllocation.dto";
import { VisibilityOption } from "@/features/roommanagement/components/VisibilityToggle";

export enum Visibility {
  Reservation = 'Reservation',
  Event = 'Event',
}

type RoomCalendarResponse = BaseResponse & {
  roomsForCalendar: RoomListDTOForCalendar;
  roomTypes: RoomTypeListDTO;
  dailyAllocationSummary: DailyAllocationSummary;
}

type ReservationForRoomAndCheckInDateResponse = BaseResponse & {
  reservations: ReservationListDTO;
}

export type RoomCalendarQueryParam = {
  bedType?: BedType | BedType[];
  roomTypeId?: number | number[];
  status?: RoomStatus | RoomStatus[];
  date?: string;
  visibility?: VisibilityOption;
}

export type CreateRoomEventParam = {
  roomTypeId: number;
  roomId: number;
  eventData: CreateRoomEventDTO;
}

export type UpdateRoomEventParam = {
  roomTypeId: number;
  roomId: number;
  eventId: number;
  eventData: UpdateRoomEventDTO;
}

export type DeleteRoomEventParam = {
  roomTypeId: number;
  roomId: number;
  eventId: number;
}

export type GetReservationForRoomAndCheckInDateParam = {
  roomTypeId: number;
  roomId: number;
  checkInDate: string;
}

export type AvailableRoomsForRoomTypeResponse = BaseResponse & {
  availableRooms: RoomListDTO;
}

export function createRoomCalendarClient(propertyId: string) {
  const baseUrl = `/property/${propertyId}/room-calendar`;
  const roomTypeBaseUrl = `property/${propertyId}/room-types`;

  const getRoomCalendarData = (filters?: RoomCalendarQueryParam) =>
    apiClient.get<RoomCalendarResponse>(baseUrl, filters);

  const createRoomEvent = ({ roomTypeId, roomId, eventData }: CreateRoomEventParam) =>
    apiClient.post<CreateRoomEventDTO, GenericHTTPResponse>(
      `${roomTypeBaseUrl}/${roomTypeId}/rooms/${roomId}/events`,
      eventData,
    );
  
  const updateRoomEvent = ({ eventId, roomTypeId, roomId, eventData }: UpdateRoomEventParam) =>
    apiClient.put<UpdateRoomEventDTO, GenericHTTPResponse>(
      `${roomTypeBaseUrl}/${roomTypeId}/rooms/${roomId}/events/${eventId}`,
      eventData,
    );

  const deleteRoomEvent= ({ eventId, roomTypeId, roomId }: DeleteRoomEventParam) =>
    apiClient.delete<GenericHTTPResponse>(
      `${roomTypeBaseUrl}/${roomTypeId}/rooms/${roomId}/events/${eventId}`,
    );

  const getReservationsForRoomAndCheckInDate = (queries: GetReservationForRoomAndCheckInDateParam) =>
    apiClient.get<ReservationForRoomAndCheckInDateResponse>(`${baseUrl}/reservations`, queries);

  const assignRoom = (data: AllocateRoomDTO) =>
    apiClient.post<AllocateRoomDTO, GenericHTTPResponse>(
      `${baseUrl}/assign-room`,
      data,
    );
  
  const removeRoomAssignment = (reservationId: number) =>
    apiClient.post<RemoveRoomAssignmentDTO, GenericHTTPResponse>(
      `${baseUrl}/remove-assignment`,
      { reservationId },
    );

  const getAvailableRoomsForRoomType = (roomTypeId: number, checkInDate: string, checkOutDate: string) =>
    apiClient.get<AvailableRoomsForRoomTypeResponse>(`${baseUrl}/available-rooms/${roomTypeId}`, { checkInDate, checkOutDate });

  return {
    getAvailableRoomsForRoomType,
    getRoomCalendarData,
    getReservationsForRoomAndCheckInDate,

    createRoomEvent,
    updateRoomEvent,
    deleteRoomEvent,

    assignRoom,
    removeRoomAssignment,
  }
}

