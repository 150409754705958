import React, { useState, useRef, useEffect } from 'react';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Checkbox } from '@/components/forms/Checkbox';
import { t, Trans } from '@lingui/macro';

interface FilterOption {
  id: number;
  name: string;
}

interface Filters {
  roomTypes: number[];
  // Add future filter types here
}

export type AssignmentVisibilityOption = 'all' | 'assigned' | 'unassigned';

interface VisibilityToggleProps {
  selected: AssignmentVisibilityOption;
  onChange: (option: AssignmentVisibilityOption) => void;
}

interface ReservationRoomAssignmentFiltersProps {
  roomTypes: FilterOption[];
  selectedRoomTypes: number[];
  onRoomTypeChange: (roomTypeIds: number[]) => void;
  onClear?: () => void;
  searchTerm: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  visibility: AssignmentVisibilityOption;
  onVisibilityChange: (option: AssignmentVisibilityOption) => void;
  // Future filter props can be added here
}

export const ReservationRoomAssignmentFilters: React.FC<ReservationRoomAssignmentFiltersProps> = ({
  roomTypes,
  selectedRoomTypes,
  onRoomTypeChange,
  onClear,
  searchTerm,
  onSearchChange,
  visibility,
  onVisibilityChange,
}) => {
  const [openMenu, setOpenMenu] = useState<'roomTypes' | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenMenu(null);
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpenMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [openMenu]);

  const handleRoomTypeToggle = (roomTypeId: number) => {
    const newSelection = selectedRoomTypes.includes(roomTypeId)
      ? selectedRoomTypes.filter(id => id !== roomTypeId)
      : [...selectedRoomTypes, roomTypeId];
    onRoomTypeChange(newSelection);
  };

  const clearRoomTypeFilter = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    onRoomTypeChange([]);
    onClear?.();
  };

  const FilterDropdown: React.FC<{
    label: string;
    options: FilterOption[];
    selectedIds: number[];
    onToggle: (id: number) => void;
    onClear: (e: React.MouseEvent) => void;
  }> = ({ label, options, selectedIds, onToggle, onClear }) => (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        onClick={() => setOpenMenu(openMenu === 'roomTypes' ? null : 'roomTypes')}
        className={`inline-flex w-full justify-center items-center rounded-md border 
          ${selectedIds.length > 0 ? 'border-blue-500 bg-blue-50' : 'border-gray-300 bg-white'}
          px-2 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50`}
      >
        <span className="mr-1">{label}</span>
        {selectedIds.length > 0 && (
          <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-1 px-1.5 py-0.5 rounded">
            {selectedIds.length}
          </span>
        )}
        <ChevronDownIcon className="-mr-1 ml-1 h-4 w-4" aria-hidden="true" />
      </button>

      {openMenu === 'roomTypes' && (
        <div className="absolute left-0 z-40 mt-1 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {selectedIds.length > 0 && (
              <button
                className="text-gray-700 block w-full px-3 py-1 text-left text-xs hover:bg-gray-100"
                onClick={onClear}
              >
                <Trans>Clear filters</Trans>
              </button>
            )}
            {options.map((option) => (
              <div key={option.id} className="px-3 py-1 hover:bg-gray-100 text-xs">
                <Checkbox
                  label={option.name}
                  checked={selectedIds.includes(option.id)}
                  onChange={() => onToggle(option.id)}
                  className="text-xs"
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  const VisibilityToggle: React.FC<VisibilityToggleProps> = ({ selected, onChange }) => {
    return (
      <div className="inline-flex rounded-md shadow-sm" role="group">
        <button
          type="button"
          className={`px-2 py-1 text-xs font-medium rounded-l-md ${
            selected === 'all'
              ? 'bg-blue-600 text-white hover:bg-blue-700'
              : 'bg-white text-gray-700 hover:bg-gray-50'
          } border border-gray-200`}
          onClick={() => onChange('all')}
        >
          <Trans>All</Trans>
        </button>
        <button
          type="button"
          className={`px-2 py-1 text-xs font-medium ${
            selected === 'assigned'
              ? 'bg-blue-600 text-white hover:bg-blue-700'
              : 'bg-white text-gray-700 hover:bg-gray-50'
          } border-t border-b border-gray-200`}
          onClick={() => onChange('assigned')}
        >
          <Trans>Assigned</Trans>
        </button>
        <button
          type="button"
          className={`px-2 py-1 text-xs font-medium rounded-r-md ${
            selected === 'unassigned'
              ? 'bg-blue-600 text-white hover:bg-blue-700'
              : 'bg-white text-gray-700 hover:bg-gray-50'
          } border border-gray-200`}
          onClick={() => onChange('unassigned')}
        >
          <Trans>Unassigned</Trans>
        </button>
      </div>
    );
  };

  return (
    <div className="mb-2 bg-gray-50 rounded-lg">
      <div className="flex items-center justify-between px-4 py-2">
        <div className="flex items-center space-x-2">
          <span className="font-medium text-gray-700 text-xs">
            <Trans>Filters:</Trans>
          </span>
          <FilterDropdown
            label={t`Room Category`}
            options={roomTypes}
            selectedIds={selectedRoomTypes}
            onToggle={handleRoomTypeToggle}
            onClear={clearRoomTypeFilter}
          />
        </div>
        <div className="flex items-center space-x-4">
          <VisibilityToggle
            selected={visibility}
            onChange={onVisibilityChange}
          />
          <div className="w-64">
            <div className="relative rounded-md shadow-sm">
              <input
                type="text"
                name="search"
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-xs leading-5"
                placeholder={t`Search by booking ID, guest name, or room type...`}
                value={searchTerm}
                onChange={onSearchChange}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

