import { useCallback } from "react";

import { CreateInvoiceItemDTO, UpdateInvoiceItemDTO } from "../../types/invoiceItem.dto";
import { AddOptionToReservationDTO, CreateReservationDTO, UpdateReservationDTO } from "../../types/reservation.dto";
import { AddReservationMemoDTO, AddReservationTopicDTO, UpdateReservationMemoDTO, UpdateReservationMemoTopicDTO } from "../../types/reservationMemo.dto";
import { AllocateRoomDTO } from "../../types/roomAllocation.dto";
import { createReservationClient, ReservationRoomAllocationQueryParam, ReservationsQueryParam } from "../api/createReservationClient";
import { GlobalState, useGlobalStore } from "../store/globalStore";
import { ReservationState, useReservationStore } from "../store/reservationStore";
import { RoomAllocationState, useRoomAllocationStore } from "../store/roomAllocationStore";
import { GuestState, useGuestStore } from "../store/guestStore";
import { AddReservationGuestDTO, UpdateReservationGuestDTO } from "../../types/guest.dto";
import { AddReservationSurveyResponseDTO } from "../../types/surveyResponse.dto";
import { ValidationError, ApiError, parseApiError } from "../api/errors";

import { UseFormReturn } from "react-hook-form";

export function useReservationService(propertyId: string, formMethods?: UseFormReturn<any>) {
  const client = createReservationClient(propertyId);

  const setError = useGlobalStore((state: GlobalState) => state.setError);
  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);

  const setReservation = useReservationStore((state: ReservationState) => state.setReservation);
  const setReservations = useReservationStore((state: ReservationState) => state.setReservations);
  const setSurveyResponse = useReservationStore((state: ReservationState) => state.setSurveyResponse);
  const setPagination = useReservationStore((state: ReservationState) => state.setPagination);

  const setRoomAllocationData = useRoomAllocationStore((state: RoomAllocationState) => state.setRoomAllocationData);

  const setGuest = useGuestStore((state: GuestState) => state.setGuest);
  const setGuests = useGuestStore((state: GuestState) => state.setGuests);

  const addReservation = useCallback(
    async (
      data: CreateReservationDTO
    ) => {
      try {
      setLoading(true);

      const res = await client.createReservation(data);
      await getReservation(res.reservation.id);
      setLoading(false);
      return true;
    } catch (e: unknown) {
      const apiError = parseApiError(e);
      if (apiError.error && Array.isArray(apiError.error) && formMethods) {
        // Handle validation errors
        apiError.error.forEach(err => {
          if (err.param == 'guests') setError(err.msg)
          else {
            formMethods.setError(err.param, {
              type: 'manual',
              message: err.msg
            });
          }
        });
      } else {
        setError(apiError.message);
      }
      setLoading(false);
    }
  },
  [setLoading, setError, client],
  );

  const getReservation = useCallback(
    async (
      reservationId: number | string | any,
    ) => {
      try {
        setLoading(true);

        const res = await client.getReservation(reservationId);
        setReservation(res.reservation);

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const getReservations = useCallback(
    async (
      data: ReservationsQueryParam
    ) => {
      try {
        setLoading(true);

        const res = await client.getReservations(data);
        setReservations(res.reservations);
        setPagination({
          ...res.pagination,
          pageSize: parseInt(data.limit),
        });

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [setReservations, setLoading, setPagination, client, setError],
  );

  const updateReservation = useCallback(
    async (
      data: UpdateReservationDTO,
    ) => {
      try {
        setLoading(true);

        const res = await client.updateReservation(data);
        setReservation(res.reservation);

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [setReservation, setLoading, setError, client],
  );

  const getRoomAllocationDataForReservation = useCallback(
    async (
      reservationId: number,
      data: ReservationRoomAllocationQueryParam,
    ) => {
      try {
        setLoading(true);
        const res = await client.getRoomAllocationDataForReservation(reservationId, data);
        setRoomAllocationData(res.roomAllocationData);
        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const allocateRoomForReservation = useCallback(
    async (
      reservationId: number,
      data: AllocateRoomDTO,
    ) => {
      try {
        setLoading(true);
        await client.allocateRoomForReservation(reservationId, data);
        setLoading(false);

      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [client.allocateRoomForReservation, setLoading, setError],
  );

  const removeRoomAllocation = useCallback(
    async (
      reservationId: number,
    ) => {
      try {
        setLoading(true);
        await client.removeRoomAllocation(reservationId);
        setLoading(false);

      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [client.removeRoomAllocation, setLoading, setError],
  );

  const linkReservation = useCallback(
    async (
      reservationId: number,
      data: number[],
    ) => {
      try {
        setLoading(true);

        const res = await client.linkReservation(reservationId, data);
        setReservation(res.reservation);

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [setReservation, setLoading, setError, client],
  );

  const addOptionsToReservation = useCallback(
    async (
      data: AddOptionToReservationDTO,
    ) => {
      try {
        setLoading(true);

        await client.addOptionToReservation(data);
        await getReservation(data.reservationId);

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [setLoading, setError, client],
  );

  const addReservationMemoTopic = useCallback(
    async (
      data: AddReservationTopicDTO,
    ) => {
      try {
        setLoading(true);

        await client.addReservationMemoTopic(data);
        await getReservation(data.reservationId);

        setLoading(false);
      } catch(e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const updateReservationMemoTopic = useCallback(
    async (
      reservationId: number,
      data: UpdateReservationMemoTopicDTO,
    ) => {
      try {
        setLoading(true);

        await client.updateReservationMemoTopic(reservationId, data);
        await getReservation(reservationId);

        setLoading(false);
      } catch(e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const deleteReservationMemoTopic = useCallback(
    async (
      reservationId: number,
      topicId: number
    ) => {
      try {
        setLoading(true);

        await client.deleteReservationMemoTopic(reservationId, topicId);
        await getReservation(reservationId);

        setLoading(false);
      } catch(e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const addReservationMemo = useCallback(
    async (
      reservationId: number,
      data: AddReservationMemoDTO,
    ) => {
      try {
        setLoading(true);

        await client.addReservationMemo(reservationId, data);
        await getReservation(reservationId);

        setLoading(false);
      } catch(e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const updateReservationMemo = useCallback(
    async (
      reservationId: number,
      topicId: number,
      data: UpdateReservationMemoDTO,
    ) => {
      try {
        setLoading(true);

        await client.updateReservationMemo(reservationId, topicId, data);
        await getReservation(reservationId);

        setLoading(false);
      } catch(e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const deleteReservationMemo = useCallback(
    async (
      reservationId: number,
      topicId: number,
      memoId: number,
    ) => {
      try {
        setLoading(true);

        await client.deleteReservationMemo(reservationId, topicId, memoId);
        await getReservation(reservationId);

        setLoading(false);
      } catch(e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const addReservationInvoiceItem = useCallback(
    async (
      reservationId: number,
      invoiceId: number,
      data: CreateInvoiceItemDTO,
    ) => {
      try {
        setLoading(true);

        await client.addReservationInvoiceItem(reservationId, invoiceId, data);
        await getReservation(reservationId);
        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const updateReservationInvoiceItem = useCallback(
    async (
      reservationId: number,
      data: UpdateInvoiceItemDTO,
    ) => {
      try {
        setLoading(true);

        await client.updateReservationInvoiceItem(reservationId, data);
        await getReservation(reservationId);
        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const deleteReservationInvoiceItem = useCallback(
    async (
      reservationId: number,
      invoiceItemId: number,
    ) => {
      try {
        setLoading(true);

        await client.deleteReservationInvoiceItem(reservationId, invoiceItemId);
        await getReservation(reservationId);
        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const getReservationGuests = useCallback(
    async (
      reservationId: number,
    ) => {
      try {
        setLoading(true);

        const res = await client.getReservationGuests(reservationId);
        setGuests(res.guests);

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );
  
  const getReservationGuest = useCallback(
    async (
      reservationId: number,
      guestId: number,
    ) => {
      try {
        setLoading(true);

        const res = await client.getReservationGuest(reservationId, guestId);
        setGuest(res.guest);

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const addReservationGuest = useCallback(
    async (
      reservationId: number,
      data: AddReservationGuestDTO,
    ) => {
      try {
        setLoading(true);

        await client.addReservationGuest(reservationId, data);
        await getReservationGuests(reservationId);

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const updateReservationGuest = useCallback(
    async (
      reservationId: number,
      data: UpdateReservationGuestDTO,
    ) => {
      try {
        setLoading(true);

        await client.updateReservationGuest(reservationId, data);
        await getReservationGuests(reservationId);

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const deleteReservationGuest = useCallback(
    async (
      reservationId: number,
      guestId: number,
    ) => {
      try {
        setLoading(true);

        await client.deleteReservationGuest(reservationId, guestId);
        await getReservationGuests(reservationId);

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const getReservationSurveyResponse = useCallback(
    async (
      reservationId: number,
    ) => {
      try {
        setLoading(true);

        const res = await client.getReservationSurveyResponse(reservationId);
        setSurveyResponse(res.surveyResponse);

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );

  const addReservationSurveyResponse = useCallback(
    async (
      reservationId: number,
      data: AddReservationSurveyResponseDTO,
    ) => {
      try {
        setLoading(true);

        await client.updateReservationSurveyResponse(reservationId, data);
        await client.getReservationSurveyResponse(reservationId);

        setLoading(false);
      } catch (e: unknown) {
        setError(String(e));
        setLoading(false);
      }
    },
    [],
  );
  return {
    addReservation,
    getReservation,
    getReservations,
    linkReservation,
    updateReservation,

    addReservationMemoTopic,
    updateReservationMemoTopic,
    deleteReservationMemoTopic,

    addReservationMemo,
    updateReservationMemo,
    deleteReservationMemo,

    getRoomAllocationDataForReservation,
    allocateRoomForReservation,
    removeRoomAllocation,

    addOptionsToReservation,
    addReservationInvoiceItem,
    updateReservationInvoiceItem,
    deleteReservationInvoiceItem,

    getReservationGuests,
    getReservationGuest,
    addReservationGuest,
    updateReservationGuest,
    deleteReservationGuest,

    addReservationSurveyResponse,
    getReservationSurveyResponse,
  }
}
