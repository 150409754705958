import { DLQJob } from "../../types/dlqJob.dto";
import { apiClient } from "./createApiClient";

type DLQJobsResponse = {
  message?: string;
  jobs: DLQJob[];
};

type RequeueResponse = {
  message: string;
};

type DeleteResponse = {
  message: string;
};

export function createMockingbirdSabreClient() {
  const getDLQJobs = () =>
    apiClient.get<DLQJobsResponse>("/mockingbird/sabre/dlq-jobs");

  const requeueJob = (jobId: string) =>
    apiClient.post<{ jobId: string }, RequeueResponse>(
      "/mockingbird/sabre/dlq-jobs/requeue",
      { jobId }
    );

  const deleteJob = (jobId: string) =>
    apiClient.delete<DeleteResponse>(`/mockingbird/sabre/dlq-jobs/${jobId}`);

  return {
    getDLQJobs,
    requeueJob,
    deleteJob,
  };
}
