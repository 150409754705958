import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

import { ReservationState, useReservationStore } from "@/store/reservationStore";
import { useReservationService } from "@/services/useReservationService";
import { Some } from "@/utils";
import { ContentLayout } from "@/components/layouts";
import { Loading } from "@/components/elements/Loading";

import { ReservationDetailSidebar } from "../components/ReservationDetailSidebar";
import { ReservationDetailHeader } from "../components/ReservationDetailHeader";

export type ReservationRouteParam = {
  propertyId: string;
  reservationId: string;
};

export function ReservationDetailView() {
  const params = useParams<ReservationRouteParam>();
  const reservation = useReservationStore(
    (state: ReservationState) => state.reservation
  )

  const propertyId = params.propertyId ?? "";

  const { getReservation } = useReservationService(propertyId)

  useEffect(() => {
    if (params.reservationId) {
      getReservation(params.reservationId)
    }
  }, [params.reservationId]);

  return Some(reservation) ? (
    <ContentLayout
      sideColumn={
        <ReservationDetailSidebar
          reservation={reservation}
          propertyId={propertyId}
        />
      }
    >
      <div className="bg-gray-50 min-h-screen">
        <ReservationDetailHeader reservation={reservation} />
        <Outlet context={{ reservation }} />
      </div>
    </ContentLayout>
  ) : (
    <Loading />
  )
};
