import ReactDOM from "react-dom/client";
import { useEffect } from "react";
import { createBrowserRouter, createRoutesFromChildren, matchRoutes, RouterProvider, useLocation, useNavigationType } from "react-router-dom";
import * as Sentry from "@sentry/react";

import "antd/dist/reset.css";
import "./index.css";
import { MockingbirdRoot } from "@/routes/mockingbird/mockingbirdRoot";
import { CreateMockTemairazuReservation } from "@/routes/mockingbird/CreateMockReservationForm";
import { PropertyListView as MockingbirdPropertyListView } from "@/routes/mockingbird/PropertyListView";
import { MockReservationListView } from "@/routes/mockingbird/MockReservationListView";
import { MockReservationDetailView } from "@/routes/mockingbird/MockReservationDetailView";

import { App } from "@/App";
import { I18nProvider } from "@/providers/I18nProvider/I18nProvider";
import { Login } from "@/features/auth/routes/Login";
import { PropertyDashboardView } from "@/features/properties/routes/PropertyDashboardView";
import { ReservationListView } from "@/features/reservation/routes/ReservationListView";
import { ResetPassword } from "@/features/auth/routes/ResetPassword";
import { RequestResetPassword } from "@/features/auth/routes/RequestResetPassword";
import { ReservationDetailView } from "@/features/reservation/routes/ReservationDetailView";
import { UserSettingsView } from "@/features/usermanagement/routes/UserSettingsView";
import { OrgSettingsView } from "@/features/usermanagement/routes/OrgSettingsView";
import { RoomAllocationView } from "@/features/room-allocation/routes/RoomAllocationView";
import { LinkReservationView } from "@/features/reservation/routes/LinkReservationView";
import { Interstitial } from "@/routes/Interstitial";
import { NotFound } from "@/routes/NotFound";
import { PropertySettingsView } from "@/features/propertymanagement/routes/PropertySettingsView";
import { InvoiceManagementView } from "@/features/invoice/routes/InvoiceManagementView";
import { MainLayout } from "@/components/layouts";
import { PublicLayout } from "@/components/layouts/PublicLayout";
import { ValidatePropertyLayout } from "@/components/layouts/ValidatePropertyLayout";
import { RoomManagementView } from "./features/roommanagement/routes/RoomManagementView";
import ReservationFormSlideOver from "./features/reservation/components/ReservationFormSlideOver";
import PlaceholderSlideOver from "./features/reservation/components/PlaceholderSlideOver";
import InvoiceItemSlideOver from "./features/invoice/components/InvoiceItemSlideOver";
import { RoomInventoryView } from "./features/roommanagement/routes/RoomInventoryView";
import {
  InfoTab,
  PreferenceTab,
  CashierTab,
  FnBTab,
  SpaTab,
  ActivityTab,
  NotesTab,
  GeneralTab,
  HistoryTab,
} from "@/features/reservation/routes/ReservationTabs";

const sentryIntegrations = [];

if (process.env.REACT_APP_SENTRY_ENABLE_TRANSACTIONS === "true") {
  sentryIntegrations.push(
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    })
  );
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: sentryIntegrations,
  tracesSampleRate: process.env.REACT_APP_SENTRY_ENABLE_TRANSACTIONS === "true" ? 1.0 : 0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <PublicLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/request-reset-password",
        element: <RequestResetPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <MainLayout />,
        children: [
          {
            path: "/",
            element: <Interstitial />,
          },
          {
            path: "/settings/*",
            element: <UserSettingsView />,
          },
          {
            path: "/org/settings/*",
            element: <OrgSettingsView />,
          },
          {
            path: "/property/settings/*",
            element: <PropertySettingsView />,
          },
        ],
      },
      {
        element: <ValidatePropertyLayout />,
        children: [
          {
            path: "/properties/:propertyId/dashboard",
            element: <PropertyDashboardView />,
          },
          {
            path: "/properties/:propertyId/reservations",
            element: <ReservationListView />,
            children: [
              {
                path: "create-new-reservation",
                element: <ReservationFormSlideOver />,
              }
            ]
          },
          {
            path: "/properties/:propertyId/reservations/:reservationId",
            element: <ReservationDetailView />,
            children: [
              {
                path: "edit-invoice-item/:invoiceItemId",
                element: <InvoiceItemSlideOver />,
              },
              {
                path: "info",
                element: <InfoTab />
              },
              {
                path: "preference",
                element: <PreferenceTab />
              },
              {
                path: "cashier",
                element: <CashierTab />
              },
              {
                path: "fnb",
                element: <FnBTab />
              },
              {
                path: "spa",
                element: <SpaTab />
              },
              {
                path: "activity",
                element: <ActivityTab />
              },
              {
                path: "notes",
                element: <NotesTab />
              },
              {
                path: "general",
                element: <GeneralTab />
              },
              {
                path: "history",
                element: <HistoryTab />
              },
              {
                path: "",
                element: <InfoTab />
              }
            ]
          },
          {
            path: "/properties/:propertyId/reservations/:reservationId/allocate-room",
            element: <RoomAllocationView />,
          },
          {
            path: "/properties/:propertyId/reservations/:reservationId/invoices",
            element: <InvoiceManagementView />,
            children: [
              {
                path: "add-invoice",
                element: <PlaceholderSlideOver />,
              },
            ],
          },
          {
            path: "/properties/:propertyId/reservations/:reservationId/invoices/:invoiceId",
            element: <InvoiceManagementView />,
            children: [
              {
                path: "add-invoice",
                element: <PlaceholderSlideOver />,
              },
              {
                path: "add-payment-transaction",
                element: <PlaceholderSlideOver />,
              },
              {
                path: "add-invoice-item",
                element: <InvoiceItemSlideOver />,
              },
              {
                path: "edit-invoice-item/:invoiceItemId",
                element: <InvoiceItemSlideOver />,
              }
            ]
          },
          {
            path: "/properties/:propertyId/reservations/:reservationId/link-reservation",
            element: <LinkReservationView />,
          },
          {
            path: "/properties/:propertyId/room-management",
            element: <RoomManagementView />,
            children: [
              {
                path: "create-new-reservation",
                element: <ReservationFormSlideOver />,
              }
            ]
          },
          {
            path: "/properties/:propertyId/room-inventory",
            element: <RoomInventoryView />,
          },
        ],
      },
    ],
  },
  // Mockingbird routes
  {
    path: "/mockingbird",
    element: <MockingbirdRoot />,
    children: [
      {
        path: "mock/properties/:propertyId/create-mock-reservation",
        element: <CreateMockTemairazuReservation />,
        handle: {
          clearState() {
            console.log("clear state called");
          },
        },
      },
      {
        path: "mock/properties",
        element: <MockingbirdPropertyListView />,
      },
      {
        path: "mock/properties/:propertyId/reservations",
        element: <MockReservationListView />,
      },
      {
        path: "mock/properties/:propertyId/reservations/:reservationId",
        element: <MockReservationDetailView />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <I18nProvider>
    <RouterProvider router={router} />
  </I18nProvider>
);
