import { create } from "zustand";
import { RoomListDTOForCalendar, DailyAllocationSummary, RoomListDTO } from "@/types/room.dto";
import { RoomTypeListDTO } from "@/types/roomType.dto";

export type RoomState = {
  readonly roomTypes: RoomTypeListDTO;
  readonly roomsForCalendar: RoomListDTOForCalendar;
  readonly dailyAllocationSummary: DailyAllocationSummary;
  readonly isCalendarLoading: boolean;
  readonly availableRoomsForRoomType: RoomListDTO;
  setRoomTypes: (roomTypes: RoomTypeListDTO) => void;
  setRoomsForCalendar: (roomsForCalendar: RoomListDTOForCalendar) => void;
  setDailyAllocationSummary: (dailyAllocationSummary: DailyAllocationSummary) => void;
  setIsCalendarLoading: (isCalendarLoading: boolean) => void;
  setAvailableRoomsForRoomType: (availableRoomsForRoomType: RoomListDTO) => void;
};

export const useRoomStore = create<RoomState>((set) => ({
  roomTypes: [],
  roomsForCalendar: {},
  dailyAllocationSummary: {
    byRoomType: {},
    overall: [],
  },
  isCalendarLoading: false,
  availableRoomsForRoomType: [],
  setRoomTypes: (roomTypes: RoomTypeListDTO) => set({ roomTypes }),
  setRoomsForCalendar: (roomsForCalendar: RoomListDTOForCalendar) => set({ roomsForCalendar }),
  setDailyAllocationSummary: (dailyAllocationSummary: DailyAllocationSummary) => set({ dailyAllocationSummary }),
  setIsCalendarLoading: (isCalendarLoading: boolean) => set({ isCalendarLoading }),
  setAvailableRoomsForRoomType: (availableRoomsForRoomType: RoomListDTO) => set({ availableRoomsForRoomType }),
}));