import React, { useMemo } from "react";
import { Select } from "antd";
import { Route, Routes } from "react-router-dom";
import UsersPage from "../components/UsersPage";
import GroupsPage from "../components/GroupsPage";
import { UserState, useUserStore } from "../../../store/userStore";
import { OrgState, useOrgStore } from "../../../store/orgStore";
import { ContentLayout } from "../../../components/layouts";
import { NavItem } from "../../../components/elements/NavItem";
import { t } from "@lingui/macro";
import { UserGroupIcon, UsersIcon, QueueListIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import QueueAdminPage from "../components/QueueAdminPage";
import { getBackendLink } from "@/utils";


export const OrgSettingsView: React.FC = () => {
  const user = useUserStore((state: UserState) => state.user);
  const orgInFocus = useOrgStore((state: OrgState) => state.orgInFocus);
  const setOrgInFocus = useOrgStore((state: OrgState) => state.setOrgInFocus);

  const choices = useMemo(
    () =>
      user?.orgPermissions.map((orgPermission) => {
        return {
          label: orgPermission.organization.legalName,
          value: orgPermission.organization.id,
        };
      }),
    [user]
  );

  if (!orgInFocus) {
    if (user?.orgPermissions.length)
      setOrgInFocus(user.orgPermissions[0].organization);
    return null;
  }

  const handleChange = (value: any) => {
    const orgPermission = user?.orgPermissions.find((orgPermission) => {
      return orgPermission.organization.id === value;
    });
    if (orgPermission) {
      setOrgInFocus(orgPermission.organization);
    }
  };

  return (
    <ContentLayout sideColumn={
      <nav className="px-3">
      <div className="space-y-1">
        <NavItem icon={<UserGroupIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />} label={t`Groups`} to="/org/settings/groups" />
        <NavItem icon={<UsersIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />} label={t`Users`} to="/org/settings/users" />
        <NavItem icon={<QueueListIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />} label={t`DLQ`} to="/org/settings/queues" />
        <a
          href={getBackendLink("/admin/queues")}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm font-medium"
        >
          <ArrowTopRightOnSquareIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />
          <span className="ml-3">{t`Queue Admin`}</span>
        </a>
      </div>
    </nav>
    }>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Select
          placeholder="Organization"
          style={{ width: 200, marginBottom: 10 }}
          defaultValue={orgInFocus.id}
          onChange={handleChange}
          options={choices}
        />
        <Routes>
          <Route
            path="groups"
            element={<GroupsPage organizationId={orgInFocus.id} />}
          />
          <Route
            path="users"
            element={<UsersPage organizationId={orgInFocus.id} />}
          />
          <Route
            path="queues"
            element={<QueueAdminPage />}
          />
        </Routes>
      </div>
    </ContentLayout>
  );
};
