import { useCallback } from "react";
import { GlobalState, useGlobalStore } from "@/store/globalStore";
import { useRoomStore } from "@/store/roomStore";
import { createRoomCalendarClient, RoomCalendarQueryParam, CreateRoomEventParam, UpdateRoomEventParam, DeleteRoomEventParam, GetReservationForRoomAndCheckInDateParam } from "@/api/createRoomCalendarClient";
import { useReservationStore } from "@/store/reservationStore";
import { AllocateRoomDTO } from "@/types/roomAllocation.dto";

export function useRoomCalendarService(propertyId: string) {
  const client = createRoomCalendarClient(propertyId);

  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);
  const setError = useGlobalStore((state: GlobalState) => state.setError);
  const { 
    setRoomsForCalendar, 
    setRoomTypes, 
    setDailyAllocationSummary, 
    setIsCalendarLoading,
    setAvailableRoomsForRoomType,
  } = useRoomStore();
  const { setReservations } = useReservationStore();

  const getRoomCalendarData = useCallback(async (filters?: RoomCalendarQueryParam) => {
    try {
      setIsCalendarLoading(true);
      const res = await client.getRoomCalendarData(filters);
      setRoomsForCalendar(res.roomsForCalendar);
      setRoomTypes(res.roomTypes)
      setDailyAllocationSummary(res.dailyAllocationSummary);
    } catch (e: unknown) {
      setError(String(e));
    }
    setIsCalendarLoading(false);
  }, [setRoomsForCalendar, client, setIsCalendarLoading, setError]);

  const createRoomEvent = useCallback(async (params: CreateRoomEventParam, filters?: RoomCalendarQueryParam) => {
    try {
      setLoading(true);

      const res = await client.createRoomEvent(params);
      await getRoomCalendarData(filters);
      return res;
    } catch (e: unknown) {
      setError(String(e));
      throw e;
    } finally {
      setLoading(false);
    }
  }, [client, setLoading, setError, getRoomCalendarData]);

  const updateRoomEvent = useCallback(async (params: UpdateRoomEventParam, filters?: RoomCalendarQueryParam) => {
    try {
      setLoading(true);

      const res = await client.updateRoomEvent(params);
      await getRoomCalendarData(filters);
      return res;
    } catch (e: unknown) {
      setError(String(e));
    } finally {
      setLoading(false);
    }
  }, [client, setLoading, setError, getRoomCalendarData]);

  const deleteRoomEvent = useCallback(async (params: DeleteRoomEventParam, filters?: RoomCalendarQueryParam) => {
    try {
      setLoading(true);

      const res = await client.deleteRoomEvent(params);
      await getRoomCalendarData(filters);
      return res;
    } catch (e: unknown) {
      setError(String(e));
    } finally {
      setLoading(false);
    }
  }, [client, setLoading, setError, getRoomCalendarData]);

  const getReservationsForRoomAndCheckInDate = useCallback(
    async (
      params: GetReservationForRoomAndCheckInDateParam,
    ) => {
      try {
        setLoading(true);

        const res = await client.getReservationsForRoomAndCheckInDate(params);
        setReservations(res.reservations);
      } catch (e: unknown) {
        setError(String(e));
      } finally {
        setLoading(false);
      }
    },
    [client, setLoading, setError],
  );

  const assignRoom = useCallback(
    async (
      data: AllocateRoomDTO,
      filters?: RoomCalendarQueryParam,
    ) => {
      try {
        setLoading(true);

        await client.assignRoom(data);
        await getRoomCalendarData(filters);
      } catch (e: unknown) {
        setError(String(e));
      } finally {
        setLoading(false);
      }
    },
    [client, setLoading, setError, getRoomCalendarData],
  );

  const removeRoomAssignment= useCallback(
    async (
      reservationId: number,
      filters?: RoomCalendarQueryParam,
    ) => {
      try {
        setLoading(true);

        await client.removeRoomAssignment(reservationId);
        await getRoomCalendarData(filters);
      } catch (e: unknown) {
        setError(String(e));
      } finally {
        setLoading(false);
      }
    },
    [client, setLoading, setError, getRoomCalendarData],
  );

  const getAvailableRoomsForRoomType = useCallback(
    async (roomTypeId: number, checkInDate: string, checkOutDate: string) => {
      try {
        const res = await client.getAvailableRoomsForRoomType(roomTypeId, checkInDate, checkOutDate);
        return res.availableRooms;
      } catch (e) {
        setError(String(e));
        return [];
      } finally {
        setLoading(false);
      }
    },
    [client, setLoading, setError],
  );

  return {
    getAvailableRoomsForRoomType,
    getReservationsForRoomAndCheckInDate,
    getRoomCalendarData,

    createRoomEvent,
    updateRoomEvent,
    deleteRoomEvent,

    assignRoom,
    removeRoomAssignment,
  };
}
