import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useRoomCalendarService } from '@/services/useRoomCalendarService';
import { ReservationListItemDTO } from '@/types/reservation.dto';
import { RoomDTO } from '@/types/room.dto';
import { t } from '@lingui/macro';
import queryString from 'query-string';
import { RoomCalendarQueryParam } from '@/api/createRoomCalendarClient';
import { LoadingSpinner } from '@/components/elements/LoadingSpinner';

interface RoomSelectionDropdownProps {
  reservation: ReservationListItemDTO;
  date: string;
  onAssign?: (roomId: number) => void;
  selectedRoomId?: number;
  onAssignmentComplete?: () => void;
  availableRooms: RoomDTO[];
  loading?: boolean;
}

export const RoomAssignmentDropdown: React.FC<RoomSelectionDropdownProps> = ({
  reservation,
  selectedRoomId,
  onAssignmentComplete,
  availableRooms,
  loading = false
}) => {
  const params = useParams<{ propertyId: string }>();
  const { assignRoom, removeRoomAssignment } = useRoomCalendarService(params.propertyId as string);
  const [searchParams] = useSearchParams();
  const [localRoomId, setLocalRoomId] = useState(selectedRoomId || undefined);
  const [isUpdating, setIsUpdating] = useState(false);
  const queryParams = searchParams.toString();
  const currentFilters = queryString.parse(queryParams) as RoomCalendarQueryParam;

  useEffect(() => {
    setLocalRoomId(selectedRoomId);
  }, [selectedRoomId]);

  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const newRoomId = value ? parseInt(value) : undefined;
    const previousRoomId = localRoomId;
    
    // Optimistically update the UI
    setLocalRoomId(newRoomId);
    setIsUpdating(true);

    try {
      if (newRoomId === undefined) {
        // Handle unassign case
        await removeRoomAssignment(reservation.id, currentFilters);
      } else {
        // Handle assign/reassign case
        await assignRoom({
          reservationId: reservation.id,
          roomId: newRoomId as number
        }, currentFilters);
      }
      
      // Only update parent state after successful server response
      await onAssignmentComplete?.();
    } catch (error) {
      // Revert to previous value if there's an error
      setLocalRoomId(previousRoomId);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="relative">
      <select
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-50 disabled:text-gray-500"
        onChange={handleChange}
        disabled={loading || isUpdating}
        value={localRoomId || ""}
      >
        <option value="">{selectedRoomId ? t`Unassign Room` : t`Select Room`}</option>
        {availableRooms.map((room) => (
          <option key={room.id} value={room.id}>
            {room.name}
          </option>
        ))}
      </select>
      {(loading || isUpdating) && (
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <LoadingSpinner size="sm" />
        </div>
      )}
    </div>
  );
};