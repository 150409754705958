import React, { useState } from "react";

interface JsonDisplayProps {
  data: string;
}

export const JsonDisplay: React.FC<JsonDisplayProps> = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  let parsedData: any;
  try {
    parsedData = JSON.parse(data);
  } catch (e) {
    return <span className="text-red-500">Invalid JSON</span>;
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (!isExpanded) {
    return (
      <button
        onClick={toggleExpand}
        className="px-2 py-1 text-sm text-blue-600 hover:text-blue-800 hover:underline"
      >
        {parsedData.type || "View Data"}
      </button>
    );
  }

  return (
    <div className="relative max-w-2xl">
      <pre className="bg-gray-50 p-4 rounded-md text-sm overflow-auto max-h-48">
        {JSON.stringify(parsedData, null, 2)}
      </pre>
      <button
        onClick={toggleExpand}
        className="absolute top-2 right-2 px-2 py-1 text-xs text-gray-500 hover:text-gray-700 bg-white rounded border shadow-sm"
      >
        Collapse
      </button>
    </div>
  );
};
