import { useEffect } from "react";
import {
  Link,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import dayjs from "dayjs";
import { Trans, t } from "@lingui/macro";

import { Reservation } from "@/types/reservation";
import { ContentLayout } from "@/components/layouts";
import { Button } from "@/components/elements";
import { DatePicker } from "@/components/forms/Datepicker";

import { useReservationList } from "../hooks/useReservationList";
import { ReservationListFilter } from "../components/ReservationListFilter";

const newReservationSuffix = "create-new-reservation";

export function ReservationListView() {
  const navigate = useNavigate();
  const params = useParams<{ propertyId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { reservations, pagination, loading } = useReservationList();

  const handleSort = (field: keyof Reservation) => {
    // Implement sorting logic here using searchParams
    // This is a placeholder for the sorting functionality
  };

  const handlePageChange = (page: number) => {
    setSearchParams((prev) => {
      prev.set("page", page.toString());
      return prev;
    });
  };

  const handleDateChange = (date: Date) => {
    const newParams = new URLSearchParams(searchParams);
    const selectedDate = dayjs(date);

    // Set the start of the selected date as check-in start
    newParams.set("checkInDateStart", selectedDate.format("YYYY-MM-DD"));
    // Set the end of the selected date as check-out end
    newParams.set("checkInDateEnd", selectedDate.format("YYYY-MM-DD"));

    // Remove the generic date parameter if it exists
    newParams.delete("date");

    setSearchParams(newParams);
  };

  return (
    <ContentLayout>
      <div className="px-4 sm:px-6 lg:px-8">
        <ReservationListFilter />

        <div className="mt-4">
          <div className="mb-4 flex justify-between items-center">
            <DatePicker
              selectedDate={
                searchParams.get("checkInDateStart")
                  ? new Date(searchParams.get("checkInDateStart")!)
                  : new Date()
              }
              onChange={handleDateChange}
              positionClass="top-24 left-4"
            />
            <Button
              size="lg"
              onClick={() => navigate(`./${newReservationSuffix}`)}
            >
              <Trans>New Reservation</Trans>
            </Button>
          </div>

          <div className="flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border border-gray-200 rounded-lg shadow">
                  {loading && (
                    <div className="absolute inset-0 bg-gray-50/50 flex items-center justify-center">
                      <div className="text-gray-500">Loading...</div>
                    </div>
                  )}
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                          onClick={() => handleSort("checkInDate")}
                        >
                          {t`Check In`}
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t`Check Out`}
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t`Room`}
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t`Guest`}
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t`# of Guests`}
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t`Booking ID`}
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t`OTA`}
                        </th>
                        <th
                          scope="col"
                          className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {reservations.map((reservation) => (
                        <tr key={reservation.id} className="hover:bg-gray-50">
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
                            <div className="max-w-[150px] truncate">
                              {dayjs(reservation.checkInDate).format(
                                "YYYY-MM-DD"
                              )}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {dayjs(reservation.checkOutDate).format(
                              "YYYY-MM-DD"
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {reservation.roomType}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {reservation.guestName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {reservation.numberOfGuests}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <div
                              className="max-w-[150px] truncate"
                              title={reservation.bookingId}
                            >
                              {reservation.bookingId}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {reservation.ota}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <Link
                              to={`/properties/${params.propertyId}/reservations/${reservation.id}`}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              <Trans>View details</Trans>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 flex justify-end">
          <div className="flex gap-2">
            <Button
              onClick={() => handlePageChange(pagination.currentPage - 1)}
              disabled={pagination.currentPage <= 1}
            >
              Previous
            </Button>
            <Button
              onClick={() => handlePageChange(pagination.currentPage + 1)}
              disabled={
                pagination.currentPage >=
                Math.ceil((pagination.total ?? 0) / pagination.pageSize)
              }
            >
              Next
            </Button>
          </div>
        </div>

        <Outlet />
      </div>
    </ContentLayout>
  );
}
