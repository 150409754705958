import React, { useEffect, useRef, useState } from 'react';
import { ReservationListItemDTO } from '@/types/reservation.dto';
import { Button } from '@/components/elements/Button';
import { t } from '@lingui/macro';
import { useParams } from 'react-router-dom';
import { useSlideOverStore } from "@/store/slideOverStore";
import AssignReservationSlideOver from './AssignReservationSlideOver';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { RoomDTO } from '@/types/room.dto';

interface ReservationPopupProps {
  reservation: ReservationListItemDTO;
  onClose: () => void;
  onUnassign: (reservation: ReservationListItemDTO) => void;
  anchorEl: HTMLElement | null;
  room: RoomDTO;
}

export const ReservationPopup: React.FC<ReservationPopupProps> = ({
  reservation,
  onClose,
  anchorEl,
  onUnassign,
  room,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const [popupStyle, setPopupStyle] = useState<{[k: string]: string}>({ visibility: 'hidden' });
  const { propertyId } = useParams<{ propertyId: string }>();
  const setSlideOver = useSlideOverStore((state) => state.setSlideOver);


  const updatePopupPosition = () => {
    if (anchorEl && popupRef.current) {
      const anchorRect = anchorEl.getBoundingClientRect();
      const viewportHeight = document.documentElement.clientHeight;
      const shouldShowAbove = anchorRect.bottom > viewportHeight / 2;
      
      popupRef.current.setAttribute('data-position', shouldShowAbove ? 'above' : 'below');
      
      setPopupStyle({
        position: 'fixed',
        top: `${shouldShowAbove ? anchorRect.top : anchorRect.bottom}px`,
        left: `${anchorRect.left}px`,
        transform: shouldShowAbove ? 'translateY(-100%)' : 'none',
        visibility: 'visible',
      });
    }
  };

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node) && event.target !== anchorEl) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscKey);
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', updatePopupPosition);
    window.addEventListener('scroll', updatePopupPosition);

    updatePopupPosition();

    return () => {
      document.removeEventListener('keydown', handleEscKey);
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', updatePopupPosition);
      window.removeEventListener('scroll', updatePopupPosition);
    };
  }, [onClose, anchorEl]);


  const handleUnassignRoom = () => {
    onUnassign(reservation);
    onClose();
  };

  const handleReassignRoom = () => {
    setSlideOver(
      <AssignReservationSlideOver
        date={reservation.checkInDate}
        room={room}
        currentAssignedReservationId={reservation.id}
      />
    );
    onClose();
  };
  const handleViewDetails = () => {
    window.open(`/properties/${propertyId}/reservations/${reservation.id}`, '_blank');
  };

  return (
    <>
      <div 
        ref={popupRef} 
        style={{...popupStyle, position: "fixed"}}
        className="bg-white rounded-lg shadow-lg p-4 max-w-sm z-50 border border-gray-200"
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
          aria-label="Close"
        >
          <XMarkIcon className="h-5 w-5" />
        </button>
        <h3 className="text-lg font-semibold mb-2">{reservation.guestName}</h3>
        <p className="text-sm text-gray-600 mb-1">{t`Reservation ID: ${reservation.temairazuOTABookingId}`}</p>
        <p className="text-sm text-gray-600 mb-1">{t`Check-in: ${reservation.checkInDate}`}</p>
        <p className="text-sm text-gray-600 mb-2">{t`Check-out: ${reservation.checkOutDate}`}</p>
        <p className="text-sm text-gray-600 mb-4">{t`Number of Guests: ${reservation.numberOfGuests}`}</p>
        <div className="flex justify-end space-x-2">
          <Button onClick={handleUnassignRoom} type="danger" size="sm">{t`Unassign`}</Button>
          <Button onClick={handleReassignRoom} size="sm">{t`Reassign`}</Button>
          <Button onClick={handleViewDetails} size="sm">{t`View`}</Button>
        </div>
      </div>
    </>
  );
};
