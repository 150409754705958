import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { t } from "@lingui/macro";

import { useRoomCalendarService } from "@/services/useRoomCalendarService";
import { GlobalState, useGlobalStore } from "@/store/globalStore";
import { RoomState, useRoomStore } from "@/store/roomStore";
import { DeleteRoomEventParam, RoomCalendarQueryParam } from "@/api/createRoomCalendarClient";
import { useNotificationStore } from "@/store/notificationStore";

export const defaultRoomCalendarQueryParam: RoomCalendarQueryParam = {
  date: new Date().toISOString().split('T')[0], // Today's date in YYYY-MM-DD format
};

export const useRoomCalendar = () => {
  const params = useParams<{ propertyId: string }>();
  const addNotification = useNotificationStore((state) => state.addNotification);
  const [searchParams, setSearchParams] = useSearchParams();
  const roomsForCalendar = useRoomStore(
    (state: RoomState) => state.roomsForCalendar
  );
  const dailyAllocationSummary = useRoomStore(
    (state: RoomState) => state.dailyAllocationSummary
  );
  const isCalendarLoading = useRoomStore(
    (state: RoomState) => state.isCalendarLoading
  );
  const propertyRoomTypes = useRoomStore(state => state.roomTypes)

  const loading = useGlobalStore((state: GlobalState) => state.loading);
  const { getRoomCalendarData, deleteRoomEvent, removeRoomAssignment } = useRoomCalendarService(
    params.propertyId as string
  );

  const queryParams = searchParams.toString();

  useEffect(() => {
    if (queryParams) {
      const parsedQueryParams = queryString.parse(queryParams);
      getRoomCalendarData({
        ...(parsedQueryParams as RoomCalendarQueryParam),
      });
    } else {
      setSearchParams(queryString.stringify(defaultRoomCalendarQueryParam));
    }
  }, [queryParams]); // eslint-disable-line

  const updateFilters = (newFilters: Partial<RoomCalendarQueryParam>) => {
    const currentFilters = queryString.parse(queryParams) as RoomCalendarQueryParam;
    const updatedFilters = { ...currentFilters, ...newFilters };
    setSearchParams(queryString.stringify(updatedFilters));
  };

  const removeRoomEvent = async (params: DeleteRoomEventParam) => {
    try {
      await deleteRoomEvent(params, queryString.parse(queryParams));
      addNotification({
        title: t`Event has been deleted successfully`,
        type: "success",
        timeoutMs: 1500,
      });
    } catch (e) {
      addNotification({
        title: t`Error`,
        type: 'error',
        message: t`Unable to delete event`,
        timeoutMs: 1500,
      });
    }
  }

  return {
    propertyRoomTypes,
    removeRoomEvent,
    roomsForCalendar,
    removeRoomAssignment,
    loading,
    updateFilters,
    searchParams,
    dailyAllocationSummary,
    isCalendarLoading,
  };
};


