import { useCallback } from "react";
import { createMockingbirdSabreClient } from "../api/createMockingbirdSabreClient";
import { GlobalState, useGlobalStore } from "../store/globalStore";
import { DLQJob } from "../../types/dlqJob.dto";

export function useMockingbirdSabreQueueService() {
  const client = createMockingbirdSabreClient();
  const setLoading = useGlobalStore((state: GlobalState) => state.setLoading);
  const setError = useGlobalStore((state: GlobalState) => state.setError);

  const getDLQJobs = useCallback(async () => {
    try {
      setLoading(true);
      const response = await client.getDLQJobs();
      setLoading(false);
      return response.jobs;
    } catch (e: unknown) {
      setLoading(false);
      setError(String(e));
      return [] as DLQJob[];
    }
  }, [client, setLoading, setError]);

  const requeueJob = useCallback(async (jobId: string) => {
    try {
      setLoading(true);
      const response = await client.requeueJob(jobId);
      setLoading(false);
      return response.message;
    } catch (e: unknown) {
      setLoading(false);
      setError(String(e));
      return null;
    }
  }, [client, setLoading, setError]);

  const deleteJob = useCallback(async (jobId: string) => {
    try {
      setLoading(true);
      const response = await client.deleteJob(jobId);
      setLoading(false);
      return response.message;
    } catch (e: unknown) {
      setLoading(false);
      setError(String(e));
      return null;
    }
  }, [client, setLoading, setError]);

  return {
    getDLQJobs,
    requeueJob,
    deleteJob,
  };
}
