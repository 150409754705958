import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ReservationStatusDTO } from "../../types/reservation.dto";

export type GlobalState = {
  readonly err: string;
  readonly loading: boolean;
  readonly isHumanizedDate: boolean;
  readonly currentProperty: string;
  readonly reservationStatuses: ReservationStatusDTO[];
  readonly sidebarCollapsed: boolean;
  readonly sidebarPeeked: boolean;
  setError: (message: string) => void;
  setLoading: (loading: boolean) => void;
  toggleHumanizedDate: () => void;
  setCurrentProperty: (propertyId: string) => void;
  setReservationStatuses: (reservationStatuses: ReservationStatusDTO[]) => void;
  setSidebarCollapsed: (collapsed: boolean) => void;
  setSidebarPeeked: (peeked: boolean) => void;
} 

export const useGlobalStore = create(
  persist(
    (set) => ({
      err: "",
      loading: false,
      isHumanizedDate: true,
      currentProperty: "",
      reservationStatuses: [],
      sidebarCollapsed: false,
      sidebarPeeked: false,
      setError: (errorMessage: string) => set({ err: errorMessage }),
      setLoading: (loading: boolean) => set({ loading }),
      toggleHumanizedDate: () => set((state: GlobalState) => ({ isHumanizedDate: !state.isHumanizedDate })),
      setCurrentProperty: (propertyId: string) => set({ currentProperty: propertyId }),
      setReservationStatuses: (reservationStatuses: ReservationStatusDTO[]) => set({ reservationStatuses }),
      setSidebarCollapsed: (collapsed: boolean) => set({ sidebarCollapsed: collapsed }),
      setSidebarPeeked: (peeked: boolean) => set({ sidebarPeeked: peeked }),
    }),
    {
      name: "global-storage",
      partialize: (state: GlobalState) => ({ 
        isHumanizedDate: state.isHumanizedDate, 
        currentProperty: state.currentProperty,
        sidebarCollapsed: state.sidebarCollapsed,
        sidebarPeeked: state.sidebarPeeked,
        reservationStatus: state.reservationStatuses,
      })
    }
  )
);

