import { ReservationDetailDTO } from "@/types/reservation.dto";
import { Title } from "@/components/elements/typography/Title";
import { GuestInformationSection } from "../components/GuestInformationSection";
import { ReservationDetailOptionTable } from "../components/ReservationDetailOptionTable";
import { ReservationMemoSection } from "../components/ReservationMemoSection";

type GeneralInfoProps = {
  reservation: ReservationDetailDTO;
};

export function GeneralInfo({ reservation }: GeneralInfoProps) {
  return (
    <>
      <Title level={4}>General Info</Title>
      <GuestInformationSection reservation={reservation} />
      <Title className="card-title" level={4}>
        Options
      </Title>
      <ReservationDetailOptionTable reservation={reservation} />
      <ReservationMemoSection reservation={reservation} />
    </>
  );
}
