import React, { Fragment, useRef, useEffect, useState, useMemo } from 'react';
import dayjs from 'dayjs';
import minMax from "dayjs/plugin/minMax"

import { RoomDTO, RoomListDTOForCalendar } from '@/types/room.dto';
import { RoomStatus } from '@/constants/common/rooms';
import { CheckCircleIcon, XCircleIcon, QuestionMarkCircleIcon, NoSymbolIcon } from '@heroicons/react/24/solid';
import { DatePicker } from "@/components/forms/Datepicker";
import { Filters, FilterOption, RoomCalendarFilter } from './RoomCalendarFilter';
import { BedType } from '@/constants/common/bedTypes';
import { useRoomCalendar } from '../hooks/useRoomCalendar';
import { CellActionMenu } from './CellActionMenu';
import { useSlideOverStore } from "@/store/slideOverStore"
import EventFormSlideOver from './EventFormSlideOver';
import { RoomEventDTO } from '@/types/roomEvent.dto';
import { EventPopup } from './EventPopup';
import { ConfirmationModal } from '@/components/elements/ConfirmationModal';
import { t, Trans } from '@lingui/macro';
import AssignReservationSlideOver from './AssignReservationSlideOver';
import { ReservationListItemDTO } from '@/types/reservation.dto';
import { ReservationPopup } from './ReservationPopup';
import { VisibilityOption } from './VisibilityToggle';
import { Outlet, useNavigate } from 'react-router-dom';
import { Loading } from '@/components/elements/Loading';
import { ReservationRoomAssignmentModal } from './ReservationRoomAssignmentModal';
import { CalendarLoadingIndicator } from './CalendarLoadingIndicator';

dayjs.extend(minMax);

interface DateColumn {
  key: string;
  header: string;
}

const StatusIcon = ({ status }: { status: RoomStatus }) => {
  const iconClasses = "w-5 h-5";
  switch (status) {
    case RoomStatus.Clean:
      return <CheckCircleIcon className={`${iconClasses} text-blue-500`} aria-hidden="true" />;
    case RoomStatus.Dirty:
      return <XCircleIcon className={`${iconClasses} text-red-500`} />;
    case RoomStatus.PendingInspection:
      return <QuestionMarkCircleIcon className={`${iconClasses} text-blue-500`} />;
    case RoomStatus.Unavailable:
      return <NoSymbolIcon className={`${iconClasses} text-gray-500`} />;
    default:
      return null;
  }
};

export function RoomCalendar() {
  const {
    propertyRoomTypes,
    roomsForCalendar,
    updateFilters,
    searchParams,
    dailyAllocationSummary,
    removeRoomEvent,
    removeRoomAssignment,
    isCalendarLoading,
  } = useRoomCalendar();
  const dateToUse = searchParams.get("date") || Date();

  // @NOTE: Event handling
  const [selectedEvent, setSelectedEvent] = useState<RoomEventDTO| null>(null);
  const [eventToDelete, setEventToDelete] = useState<RoomEventDTO | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [selectedReservation, setSelectedReservation] = useState<ReservationListItemDTO | null>(null);
  const [reservationAnchorEl, setReservationAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedRoom, setSelectedRoom] = useState<RoomDTO | null>(null);
  const [showUnassignConfirmation, setShowUnassignConfirmation] = useState(false);
  const [reservationToUnassign, setReservationToUnassign] = useState<ReservationListItemDTO | null>(null);

  const [stickyHeaderHeight, setStickyHeaderHeight] = useState(0);
  const headerRef = useRef<HTMLTableSectionElement>(null);
  const [startDate, setStartDate] = useState(dayjs(dateToUse).toDate());
  const [_, setFilters] = useState<Filters>({
    roomTypes: [],
    bedTypes: [],
    roomStatuses: [],
    visibility: "both",
  });

  const setSlideOver = useSlideOverStore((state) => state.setSlideOver);
  const navigate = useNavigate();

  const currentFilters: Filters = useMemo(() => ({
    roomTypes: (searchParams.getAll('roomTypeId') || []).map(Number),
    bedTypes: (searchParams.getAll('bedType') || []) as BedType[],
    roomStatuses: (searchParams.getAll('status') || []) as RoomStatus[],
    visibility: searchParams.get("visibility") as VisibilityOption,
  }), [searchParams]); 

  const [showAssignmentModal, setShowAssignmentModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedRoomTypeId, setSelectedRoomTypeId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (headerRef.current) {
      setStickyHeaderHeight(headerRef.current.offsetHeight);
    }
  }, []);

  const handleEventClick = (event: RoomEventDTO, element: HTMLElement) => {
    setSelectedEvent(event);
    setAnchorEl(element);
  };

  const handleReservationClick = (reservation: ReservationListItemDTO, room: RoomDTO, element: HTMLElement) => {
    setSelectedReservation(reservation);
    setSelectedRoom(room);
    setReservationAnchorEl(element);
  };

  const handleUnassignReservation = (reservation: ReservationListItemDTO) => {
    setReservationToUnassign(reservation);
    setShowUnassignConfirmation(true);
  };

  const confirmUnassignReservation = async () => {
    if (reservationToUnassign) {
      await removeRoomAssignment(reservationToUnassign.id, { ...currentFilters, date: dateToUse });
      setShowUnassignConfirmation(false);
      setReservationToUnassign(null);
      // Optionally, refresh the calendar data here
      // await getRoomCalendarData(currentFilters);
    }
  };

  const handleEditEvent = () => {
    if (selectedEvent) {
      setSlideOver(
        <EventFormSlideOver
          date={selectedEvent.dateTimeStart}
          roomId={selectedEvent.roomId}
          roomTypeId={selectedEvent.roomTypeId}
          event={selectedEvent}
        />
      );
    }
  };

  const handleAssignReservation = (date: string, room: RoomListDTOForCalendar[string][number]) => {
    setSlideOver(
      <AssignReservationSlideOver
        date={date}
        room={room}
      />
    );
  };

  const handleDeleteEvent = (event: RoomEventDTO) => {
    setEventToDelete(event);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteEvent = async () => {
    if (eventToDelete) {
      const { id: eventId, roomId, roomTypeId } = eventToDelete;
      await removeRoomEvent({ roomId, roomTypeId, eventId })
      setEventToDelete(null);
    }
    setShowDeleteConfirmation(false);
  };

  const handleFilterChange = (newFilters: Filters) => {
    setFilters(newFilters);
    updateFilters({
      roomTypeId: newFilters.roomTypes as number[],
      bedType: newFilters.bedTypes as BedType[],
      status: newFilters.roomStatuses as RoomStatus[],
      visibility: newFilters.visibility,
    });
  };

  const generateDateColumns = (start: Date): DateColumn[] => {
    const columns: DateColumn[] = [];
    const startDayjs = dayjs(start);
    for (let i = 0; i < 10; i++) {
      const date = startDayjs.add(i, 'day');
      columns.push({
        key: date.format('YYYY-MM-DD'),
        header: date.format('ddd, MMM DD'),
      });
    }
    return columns;
  };

  const dateColumns = generateDateColumns(startDate);

  const handleCreateEvent = (date: string, roomId: number, roomTypeId: number) => {
    setSlideOver(
      <EventFormSlideOver
         date={date}
         roomId={roomId}
         roomTypeId={roomTypeId}
       />
    );
  };

  const handleCreateReservation = (date: string, roomId: number, roomTypeId: number) => {
    const searchParams = new URLSearchParams({
      checkInDate: date,
      roomIdToInsert: roomId.toString(),
      roomTypeIdToInsert: roomTypeId.toString()
    });
    
    navigate(`./create-new-reservation?${searchParams.toString()}`);
  };

  const handleDateChange = (date: Date) => {
    setStartDate(date);
    updateFilters({ date: dayjs(date).format('YYYY-MM-DD') });
  };

  const roomTypes: FilterOption[] = useMemo(() => 
    propertyRoomTypes.map(roomType => ({
      id: roomType.id,
      name: roomType.name,
    })),
    [propertyRoomTypes]
  );

  const renderTableHeader = () => (
    <thead ref={headerRef}>
      <tr className="divide-x divide-gray-200">
        <th className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-2 pl-2 pr-1 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-3 lg:pl-4 w-52">
          <DatePicker
            selectedDate={startDate}
            onChange={handleDateChange}
          />
        </th>
        {dateColumns.map((column) => (
          <th
            key={column.key}
            scope="col"
            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-1 py-2 text-center text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter w-32"
          >
            {column.header}
          </th>
        ))}
      </tr>
    </thead>
  );

  const renderOverallDailySummaryRow = () => {
    const cells: React.ReactNode[] = [];
    const { overall } = dailyAllocationSummary;

    cells.push(
      <td key="summary" className="whitespace-nowrap border-b border-gray-200 py-2 pl-2 pr-1 text-xs font-medium text-gray-900 sm:pl-3 lg:pl-4 w-40 bg-blue-50">
        <div className="flex items-center">
          <span className="font-bold">{t`Summary`}</span>
        </div>
      </td>
    );

    for (const summary of overall) {
      const { allocatedReservations, totalReservations } = summary;
      cells.push(
        <td 
          key={summary.date} 
          className="border-b border-gray-200 px-1 py-1 text-xs text-gray-500 bg-blue-50 text-center cursor-pointer hover:bg-blue-100"
          onClick={() => {
            setSelectedDate(summary.date);
            setShowAssignmentModal(true);
          }}
        >
          <span>
            <Trans>{`${allocatedReservations}/${totalReservations} assigned`}</Trans>
          </span>
        </td>
      );
    }
    return <tr className='divide-x divide-gray-200'>{cells}</tr>;
  };

  const renderCells = (room: RoomListDTOForCalendar[string][number]): React.ReactNode[] => {
    const cells: React.ReactNode[] = [];
    let skipDays = 0;
  
    dateColumns.forEach((column, index) => {
      if (skipDays > 0) {
        skipDays--;
        return;
      }
  
      const event = room.events.find(e => 
        dayjs(column.key).isBetween(dayjs(e.dateTimeStart), dayjs(e.dateTimeEnd), 'day', '[]')
      );

      const reservation = room.reservations.find(r => 
        dayjs(column.key).isBetween(dayjs(r.checkInDate), dayjs(r.checkOutDate), 'day', '[)')
      );

      // @NOTE: Checkout day
      // will keep this for future UI improvements.
      const isCheckoutDay = room.reservations.some(r => 
        dayjs(column.key).isSame(dayjs(r.checkOutDate), 'day')
      );
  
      if (event) {
        const eventStartDate = dayjs.max(dayjs(event.dateTimeStart), dayjs(column.key));
        const eventEndDate = dayjs.min(dayjs(event.dateTimeEnd), dayjs(dateColumns[dateColumns.length - 1].key));
        const duration = Math.min(eventEndDate.diff(eventStartDate, 'day') + 1, dateColumns.length - index);
        skipDays = duration - 1;
  
        cells.push(
          <td key={column.key} colSpan={duration} className="border-b border-gray-200 px-1 py-1 text-xs text-gray-500">
            <div
              className="bg-yellow-100 p-1 text-xs h-full cursor-pointer overflow-hidden max-w-[200px]"
              onClick={(e) => handleEventClick(event, e.currentTarget)}
            >
              <div className="truncate">{`[${event.eventType}] - ${event.eventName}`}</div>
            </div>
          </td>
        );
      } else if (reservation) {
        const reservationStartDate = dayjs.max(dayjs(reservation.checkInDate), dayjs(column.key));
        const duration = Math.min(
          dayjs(reservation.checkOutDate).subtract(1, 'day').diff(reservationStartDate, 'day') + 1,
          dateColumns.length - index
        );
        skipDays = duration - 1;
  
        cells.push(
          <td key={column.key} colSpan={duration} className="border-b border-gray-200 px-1 py-1 text-xs text-gray-500">
            <div 
              className="bg-blue-100 p-1 text-xs h-full cursor-pointer overflow-hidden max-w-[200px]"
              onClick={(e) => handleReservationClick(reservation, room, e.currentTarget)}
            >
              <div className="truncate">{reservation.guestName}</div>
              <div className="truncate">{reservation.reservationStatus}</div>
            </div>
          </td>
        );
      } else {
        cells.push(
          <td key={column.key} className="border-b border-gray-200 p-0 h-10">
            <CellActionMenu
              onCreateEvent={() => handleCreateEvent(column.key, room.id, room.roomTypeId)}
              onCreateReservation={() => handleCreateReservation(column.key, room.id, room.roomTypeId)}
              onAssignReservation={() => handleAssignReservation(column.key, room)}
            />
          </td>
        );
      }
    });
  
    return cells;
  };

  const renderTableBody = (roomsForCalendar: RoomListDTOForCalendar) => (
    <tbody className="divide-y divide-gray-200 bg-white">
      {Object.entries(roomsForCalendar).map(([roomType, rooms], groupIndex) => (
        <Fragment key={roomType}>
          <tr className="border-t border-gray-200 divide-x divide-gray-200">
            <th
              scope="colgroup"
              className="sticky z-0 bg-gray-50 py-1 pl-2 pr-1 text-left text-xs font-semibold text-gray-900 sm:pl-3 lg:pl-4"
              style={{ top: `${stickyHeaderHeight}px` }}
            >
              {roomType}
            </th>
            {dailyAllocationSummary.byRoomType[roomType].map((summary) => (
              <th 
                key={summary.date} 
                scope="colgroup"
                className="sticky z-5 border-b border-gray-200 px-1 py-1 text-xs text-gray-500 bg-blue-50 text-center cursor-pointer hover:bg-blue-100"
                onClick={() => {
                  setSelectedRoomTypeId(summary.roomTypeId);
                  setSelectedDate(summary.date);
                  setShowAssignmentModal(true);
                }}
                style={{ top: `${stickyHeaderHeight}px` }}
              >
                <span>
                  <Trans>{`${summary.allocatedReservations}/${summary.totalReservations} assigned`}</Trans>
                </span>
              </th>
            ))}
          </tr>
          {rooms.map((room) => (
            <tr key={room.id} className="divide-x divide-gray-200">
              <td className="whitespace-nowrap border-b border-gray-200 py-2 pl-2 pr-1 text-xs font-medium text-gray-900 sm:pl-3 lg:pl-4 w-40">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-1">
                    <span className="font-bold">{room.name}</span>
                    <span className="text-gray-500 text-xs">{room.bedType}</span>
                    <span className="text-gray-500 text-xs">{room.roomUsageRate}%</span>
                  </div>
                  <StatusIcon status={room.status} />
                </div>
              </td>
              {renderCells(room)}
            </tr>
          ))}
        </Fragment>
      ))}
    </tbody>
  );

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <RoomCalendarFilter
        roomTypes={roomTypes}
        initialFilters={currentFilters}
        onFilterChange={handleFilterChange}
        isLoading={isCalendarLoading}
      />
      <div className="mt-4 mb-8 flow-root relative">
        <div className="inline-block min-w-full py-0 align-middle">
          <div className="shadow ring-1 ring-black ring-opacity-5">
            <table className="min-w-full border-separate border-spacing-0 divide-y divide-gray-300">
              {renderTableHeader()}
              <tbody>
                {renderOverallDailySummaryRow()}
              </tbody>
              {renderTableBody(roomsForCalendar)}
            </table>
          </div>
        </div>
      </div>

      {selectedEvent && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <EventPopup
            event={selectedEvent}
            onEdit={handleEditEvent}
            onDelete={handleDeleteEvent}
            onClose={() => {
              setSelectedEvent(null);
              setAnchorEl(null);
            }}
            anchorEl={anchorEl}
          />
        </div>
      )}

      {selectedReservation && selectedRoom && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <ReservationPopup
            reservation={selectedReservation}
            onClose={() => {
              setSelectedReservation(null);
              setSelectedRoom(null);
              setReservationAnchorEl(null);
            }}
            onUnassign={handleUnassignReservation}
            anchorEl={reservationAnchorEl}
            room={selectedRoom}
          />
        </div>
      )}

      <ConfirmationModal
        isOpen={showUnassignConfirmation}
        onClose={() => setShowUnassignConfirmation(false)}
        onConfirm={confirmUnassignReservation}
        title={t`Unassign Room`}
        message={t`Are you sure you want to unassign this room from the reservation? This action cannot be undone.`}
        type="warning"
      />

      <ConfirmationModal
        isOpen={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={confirmDeleteEvent}
        title={t`Delete Event`}
        message={t`Are you sure you want to delete this event? This action cannot be undone.`}
        type="warning"
      />

      <ReservationRoomAssignmentModal
        isOpen={showAssignmentModal}
        onClose={() => {
          setShowAssignmentModal(false);
          setSelectedDate(null);
          setSelectedRoomTypeId(undefined);
        }}
        date={selectedDate || ''}
        roomTypeId={selectedRoomTypeId}
        propertyRoomTypes={propertyRoomTypes}
      />

      <Outlet />
    </div>
  );
}
