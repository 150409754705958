import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useReservationStore } from "@/store/reservationStore";
import { useReservationService } from "@/services/useReservationService";
import { ReservationsQueryParam } from "@/api/createReservationClient";
import { useRoomStore } from "@/store/roomStore";
import { RoomState } from "@/store/roomStore";

interface UseRoomCalendarReservationProps {
  checkInDate?: string;
  roomTypeId?: string | string[] | number | number[] | any;
  shouldExecute?: boolean;
}

export const useRoomCalendarReservation = ({ 
  checkInDate,
  roomTypeId,
  shouldExecute = false 
}: UseRoomCalendarReservationProps) => {
  const params = useParams<{ propertyId: string }>();
  const loading = useRoomStore((state: RoomState) => state.isCalendarLoading);
  const reservations = useReservationStore(state => state.reservations);
  const setReservations = useReservationStore(state => state.setReservations);
  const { getReservations } = useReservationService(params.propertyId as string);

  useEffect(() => {
    if (checkInDate !== "" && shouldExecute) {
      const queryParams: ReservationsQueryParam = {
        checkInDate,
        ...(roomTypeId ? { roomTypeId } : {}),
        limit: "100",
        page: "1"
      };
      
      getReservations(queryParams);
    }
  }, [checkInDate, roomTypeId, shouldExecute]);

  return {
    reservations,
    loading,
    getReservations,
    setReservations,
  };
};