import { useOutletContext } from "react-router-dom";
import { ReservationDetailDTO } from "@/types/reservation.dto";
import { ReservationInfo } from "./ReservationInfo";
import { PlaceholderTab } from "./PlaceholderTab";
import { CashierInfo } from "./CashierInfo";
import { GeneralInfo } from "./GeneralInfo";
import { Preference } from "./Preference";

type ReservationContextType = {
  reservation: ReservationDetailDTO;
};

export function InfoTab() {
  const { reservation } = useOutletContext<ReservationContextType>();
  return <ReservationInfo reservation={reservation} />;
}

export function PreferenceTab() {
  const { reservation } = useOutletContext<ReservationContextType>();
  return <Preference reservation={reservation} />;
}

export function CashierTab() {
  const { reservation } = useOutletContext<ReservationContextType>();
  return <CashierInfo reservation={reservation} />;
}

export function FnBTab() {
  return <PlaceholderTab title="F&B" />;
}

export function SpaTab() {
  return <PlaceholderTab title="SPA" />;
}

export function ActivityTab() {
  return <PlaceholderTab title="Activity" />;
}

export function NotesTab() {
  return <PlaceholderTab title="Notes" />;
}

export function GeneralTab() {
  const { reservation } = useOutletContext<ReservationContextType>();
  return <GeneralInfo reservation={reservation} />;
}

export function HistoryTab() {
  return <PlaceholderTab title="History" />;
}
