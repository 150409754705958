import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import queryString from "query-string";
import { Trans, t } from "@lingui/macro";

import { OTAState, useOtaStore } from "@/store/otaStore";
import { useOtaService } from "@/services/useOtaService";
import { ReservationsQueryParam } from "@/api/createReservationClient";
import { Button } from "@/components/elements";

import { defaultReservationQueryParam } from "../constants";

export type CustomSearchInputs = {
  searchDateType: string;
  searchDates: Dayjs[];
  otas: string[];
  reservationStatus?: string;
  paymentStatus?: string;
};

const searchDateTypes = {
  CHECK_IN_DATE: "checkInDate",
  BOOKING_DATE: "bookingDate",
};

export function ReservationListFilter() {
  const [_, setSearchParams] = useSearchParams();
  const otas = useOtaStore((state: OTAState) => state.otas);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<CustomSearchInputs>();
  const otaBookingIdSearchForm = useForm<{ otaBookingId: string }>();

  const handleCustomSearch: SubmitHandler<CustomSearchInputs> = (data) => {
    const filterParams: ReservationsQueryParam = {
      limit: "10",
      page: "1",
    };

    if (data.searchDates && data.searchDates.length) {
      const startSearchDate = data.searchDates[0].format("YYYY-MM-DD");
      const endSearchDate = data.searchDates[1].format("YYYY-MM-DD");

      if (data.searchDateType === searchDateTypes.CHECK_IN_DATE) {
        filterParams.checkInDateStart = startSearchDate;
        filterParams.checkInDateEnd = endSearchDate;
      }

      if (data.searchDateType === searchDateTypes.BOOKING_DATE) {
        filterParams.bookingDateStart = startSearchDate;
        filterParams.bookingDateEnd = endSearchDate;
      }
    }

    if (data.otas && data.otas.length) filterParams.otas = data.otas;
    if (data.paymentStatus) filterParams.paymentStatus = data.paymentStatus;

    setSearchParams(queryString.stringify(filterParams));
  };

  const handleResetFilter = () => {
    setSearchParams(queryString.stringify(defaultReservationQueryParam));
    reset();
  };

  return (
    <div className="mb-6 bg-white rounded-lg shadow">
      <div className="p-4">
        <form onSubmit={handleSubmit(handleCustomSearch)}>
          <div className="flex items-end gap-4">
            {/* Date Type Select */}
            <div className="w-40">
              <label className="block text-xs font-medium text-gray-700 mb-1">
                {t`Date Type`}
              </label>
              <Controller
                control={control}
                name="searchDateType"
                defaultValue={searchDateTypes.CHECK_IN_DATE}
                render={({ field }) => (
                  <select
                    {...field}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  >
                    <option
                      value={searchDateTypes.CHECK_IN_DATE}
                    >{t`Check In`}</option>
                    <option
                      value={searchDateTypes.BOOKING_DATE}
                    >{t`Booking Date`}</option>
                  </select>
                )}
              />
            </div>

            {/* Date Range */}
            <div className="flex-1 max-w-md">
              <label className="block text-xs font-medium text-gray-700 mb-1">
                {t`Date Range`}
              </label>
              <Controller
                control={control}
                name="searchDates"
                render={({ field }) => (
                  <div className="flex gap-2">
                    <input
                      type="date"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      onChange={(e) => {
                        const startDate = dayjs(e.target.value);
                        field.onChange([
                          startDate,
                          field.value?.[1] || startDate.add(1, "month"),
                        ]);
                      }}
                      value={field.value?.[0]?.format("YYYY-MM-DD") || ""}
                    />
                    <input
                      type="date"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      onChange={(e) => {
                        const endDate = dayjs(e.target.value);
                        field.onChange([
                          field.value?.[0] || endDate.subtract(1, "month"),
                          endDate,
                        ]);
                      }}
                      value={field.value?.[1]?.format("YYYY-MM-DD") || ""}
                    />
                  </div>
                )}
              />
            </div>

            {/* OTA Select */}
            <div className="w-48">
              <label className="block text-xs font-medium text-gray-700 mb-1">
                {t`OTA`}
              </label>
              <Controller
                control={control}
                name="otas"
                render={({ field }) => (
                  <select
                    {...field}
                    multiple
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    size={1}
                  >
                    {otas?.map((ota) => (
                      <option key={ota.id} value={ota.id}>
                        {ota.name}
                      </option>
                    ))}
                  </select>
                )}
              />
            </div>

            {/* Payment Status */}
            <div className="w-40">
              <label className="block text-xs font-medium text-gray-700 mb-1">
                {t`Payment Status`}
              </label>
              <Controller
                control={control}
                name="paymentStatus"
                render={({ field }) => (
                  <select
                    {...field}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="">{t`All`}</option>
                    <option value="paid">{t`Paid`}</option>
                    <option value="not paid">{t`Not Paid`}</option>
                  </select>
                )}
              />
            </div>

            {/* Buttons */}
            <div className="flex gap-2">
              <Button
                type="default"
                onClick={handleResetFilter}
                disabled={isSubmitting}
              >
                <Trans>Reset</Trans>
              </Button>
              <Button type="primary" disabled={isSubmitting}>
                <Trans>Search</Trans>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
